<template>
  <v-app id="veetal" :class="{ loading_screenshot: loading_screenshot }">
    <v-navigation-drawer
      v-model="drawer"
      :bottom="$vuetify.breakpoint.xsOnly"
      :expand-on-hover="
        mini && ($vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly)
      "
      :disable-route-watcher="true"
      mini-variant-width="80"
      app
    >
      <vue-scroll :ops="ops">
        <v-list dense class="pt-1">
          <v-list-item @click="showHotel" class="menu_first_node">
            <v-list-item-avatar
              v-if="
                current_hotel &&
                  current_hotel.settings &&
                  current_hotel.settings.is_chain
              "
            >
              <v-img :src="chain.logo"></v-img>
            </v-list-item-avatar>
            <!-- <v-list-item-avatar > -->
            <v-btn
              fab
              small
              depressed
              v-else-if="current_hotel && current_hotel.settings"
              :color="current_hotel.settings.color"
              class="v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
              >{{ current_hotel.settings.abbreviation }}
            </v-btn>

            <!-- </v-list-item-avatar> -->
            <v-list-item-content class="mt-1 ml-n2">
              <v-list-item-title
                class="subtitle-2 text-uppercase"
                v-if="current_hotel"
              >
                <template
                  v-if="
                    current_hotel.settings && current_hotel.settings.is_chain
                  "
                  >{{ chain.name }}</template
                >
                <template v-else>{{ current_hotel.name }}</template>
              </v-list-item-title>
              <!-- <v-list-item-subtitle>versión 6.0</v-list-item-subtitle> -->
            </v-list-item-content>

            <v-app-bar-nav-icon
              class="mt-1"
              v-if="$vuetify.breakpoint.xsOnly"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-list-item>
          <!-- <v-divider></v-divider> -->

          <div class="mt-3 menu_second_node"></div>
          <template v-for="item in menuItems">
            <!-- Group nodes -->
            <v-row v-if="item.heading" :key="item.heading" align="center">
              <v-col cols="12">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
            </v-row>
            <v-list-group
              class="text-left"
              v-else-if="item.children"
              :value="isCurrentPageParent(item)"
              :key="item.text"
              :prepend-icon="getParentIcon(item)"
              append-icon="keyboard_arrow_down"
              color="secondary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-if="item.literal">
                    {{ $t(item.literal) }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- Group Child nodes -->
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                @click="clickMenu(child)"
                :class="{
                  'v-list-item--active secondary--text': isCurrentPage(child)
                }"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>
                    {{ getIcon(child) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="child.literal">
                    {{ $t(child.literal) }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- END Group Child nodes -->
            </v-list-group>
            <!-- END Group nodes -->

            <!-- Individual nodes  -->
            <v-list-item
              v-else
              :key="item.literal"
              :class="{
                'v-list-item--active secondary--text': isCurrentPage(item),
                'mt-8': item.page == 'highlights'
              }"
              @click="clickMenu(item)"
            >
              <v-list-item-action>
                <v-icon>{{ getIcon(item) }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="item.literal">
                  {{ $t(item.literal) }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <!-- Settings Individual nodes  -->
        <v-list
          dense
          class="pb-0"
          :class="{ 'pull-bottom': !$vuetify.breakpoint.xsOnly }"
        >
          <v-list dense>
            <template v-for="item in settingsItems">
              <!-- Group nodes -->
              <v-row v-if="item.heading" :key="item.heading" align="center">
                <v-col cols="12">
                  <v-subheader v-if="item.heading">
                    {{ item.heading }}
                  </v-subheader>
                </v-col>
              </v-row>
              <v-list-group
                :color="menu_color"
                class="text-left"
                v-else-if="item.children"
                :value="isCurrentPageParent(item)"
                :key="item.text"
                :prepend-icon="item.model ? item['icon'] : item['icon']"
                append-icon="keyboard_arrow_down"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.literal">
                      {{ $t(item.literal) }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <!-- Group Child nodes -->
                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="i"
                  @click="clickMenu(child)"
                  :class="{
                    'v-list-item--active secondary--text': isCurrentPage(child)
                  }"
                >
                  <v-list-item-action v-if="child.icon">
                    <v-icon style="/*margin-left: 10px;*/">
                      {{ getIcon(child) }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="child.literal">
                      {{ $t(child.literal) }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- END Group Child nodes -->
              </v-list-group>
              <!-- END Group nodes -->
              <!-- NO Individual nodes in settings  -->
            </template>
          </v-list>

          <v-divider class="mb-1"></v-divider>
          <v-list-item
            class="bottom-list-item"
            :key="'user'"
            @click="showCurrentUser"
          >
            <v-list-item-action v-if="current_user">
              <v-icon style="color:#333333;">
                mdi-alpha-{{
                  current_user.name.substring(0, 1).toLowerCase()
                }}-circle
                <!-- alpha-s-circle mdi-account-circle-outline -->
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="current_user">
                {{ current_user.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="bottom-list-item"
            :key="'disconnect'"
            @click="showDisconnect"
          >
            <v-list-item-action>
              <v-icon style="color:#333333;"> mdi-power </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("disconnect") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="bottom-list-item"
            :class="{
              'v-list-item--active secondary--text': isVersionPage
            }"
            :key="'a'"
            @click="clickMenu({ page: 'version' })"
          >
            <v-list-item-action>
              <v-list-item-avatar
                size="20"
                style="margin:0 auto !important;"
                tile
              >
                <img src="/img/logo/veetal-imagotipo-sma.png" />
              </v-list-item-avatar>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                veetal
              </v-list-item-title>
              <v-list-item-subtitle
                class="grey--text lighten-1"
                v-if="chain && chain.version"
              >
                {{ chain.version.split(" ")[1] }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </vue-scroll>
    </v-navigation-drawer>

    <!-- PAGE -->
    <transition>
      <!-- <keep-alive> -->
      <!-- <div>{{ current_user.permissions }}</div> -->
      <router-view :key="$route.fullPath"></router-view>
      <!-- </keep-alive> -->
    </transition>

    <!-- MOBILE BOTTOM MENU -->
    <v-bottom-navigation
      class="hidden-sm-and-up"
      grow
      app
      fixed
      :color="menu_color"
    >
      <v-btn
        v-for="item in mobileItems"
        :key="item.text"
        :class="{ 'v-btn--active': isCurrentPage(item) }"
        @click="
          $router
            .push({
              name: item.page,
              params: item.page_params ? item.page_params : {}
            })
            .catch(err => {})
        "
      >
        <v-icon>{{ getIcon(item) }}</v-icon>
      </v-btn>
      <v-btn @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :multi-line="true"
    >
      <div>
        <h4 class="uppercase">{{ snackbar.title }}</h4>
        <span>{{ snackbar.text }}</span>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          dark
          icon
          text
          x-small
          @click="snackbar.snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- CURRENT USER INFO -->
    <v-dialog
      v-model="show_current_user"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="460"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center">
          {{ current_user.username }}
          <v-spacer></v-spacer>
          <v-btn
            style="position:absolute; top:13px; right:10px;"
            icon
            @click="show_current_user = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <div class="pt-4">
          <v-tabs v-model="tab" grow mandatory>
            <v-tab>{{ $t("general_data") }}</v-tab>
            <v-tab>{{ $t("password") }}</v-tab>
          </v-tabs>
        </div>
        <v-form v-show="tab == 0" v-model="valid">
          <v-card-text class="white">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="user.name"
                  :label="$t('name')"
                  type="text"
                  :rules="name_rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="user.surnames"
                  :label="$t('surname')"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper">
                    {{ $t("language") }}
                  </div>
                </div>
                <v-btn-toggle
                  v-model="user.language"
                  mandatory
                  rounded
                  background-color="white"
                  color="primary"
                >
                  <v-btn
                    v-for="language_option in language_options"
                    :key="language_option.iso"
                    :value="language_option.iso"
                  >
                    <flag :iso="language_option.flag" class="mr-0 mr-sm-2" />
                    <span class="d-none d-sm-flex">{{
                      language_option.name
                    }}</span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 px-6">
            <v-btn
              color="success"
              rounded
              depressed
              class="pl-5 pr-5"
              :disabled="!valid"
              :loading="saving"
              @click="changeUserData()"
            >
              {{ $t("change_data") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-form v-show="tab == 1" v-model="password_valid" ref="password_form">
          <v-card-text class="white">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  clearable
                  v-model="new_password"
                  :label="$t('new_password')"
                  type="password"
                  :rules="password_rules"
                  @change="repeat_pwd_validate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="new_password2"
                  clearable
                  v-model="new_password2"
                  :label="$t('repeat_password')"
                  type="password"
                  :rules="repeatPasswordRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 px-6">
            <v-btn
              color="info"
              rounded
              depressed
              class="px-5"
              :disabled="!password_valid"
              @click="changeUserPassword()"
            >
              {{ $t("change") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DISCONNECT -->
    <v-dialog v-model="show_disconnect" max-width="400">
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("sure_disconnect")
        }}</v-card-title>
        <!-- <v-card-text class="text-center justify-center" v-if="silo">
          Al esborrar la comanda per <b>{{ silo.name }}</b> no podràs
          recuperar-la.
        </v-card-text> -->
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="disconnect()"
          >
            {{ $t("exit") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="show_disconnect = false"
          >
            {{ $t("continue_connected") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- NO DATA -->
    <v-dialog v-model="show_no_data" persistent max-width="400">
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("no_data")
        }}</v-card-title>
        <v-card-text class="text-center justify-center">
          <br /><img
            src="/img/logo/veetal-imagotipo-sma.png"
            alt="loader"
          /><br /><br />
          <template
            v-if="
              current_hotel &&
                current_hotel.trial &&
                current_hotel.trial.trial_init_date
            "
          >
            <p>{{ $t("trial_init_date") }}</p>
            <p class="text-h5">
              {{ current_hotel.trial.trial_init_date | formatDateFull }}
            </p>
            <p>{{ $t("trial_init_date_message") }}</p>
          </template>
          <p v-else>{{ $t("no_data_message") }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- MISSING CONFIGURATION -->
    <v-dialog v-model="show_not_configured" persistent max-width="500">
      <v-card>
        <v-card-title center class="headline justify-center">
          {{ $t("missing_config") }}
        </v-card-title>
        <v-card-text class="text-center justify-center">
          <br /><img
            src="/img/logo/veetal-imagotipo-sma.png"
            alt="loader"
          /><br /><br />
          <p>{{ $t("missing_config_message") }}</p>
          <p
            v-if="
              current_hotel &&
                current_hotel.trial &&
                current_hotel.trial.call_me
            "
          >
            {{ $t("missing_config_call") }}
          </p>
          <p v-else>{{ $t("missing_config_yourself") }}</p>

          <v-form v-model="configuration_valid" ref="configuration_form">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <label for="">{{ $t("hotel").toUpperCase() }}</label>
                <a-booking-url-field
                  v-model="hotel_booking_url"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
              </v-col>
              <v-col cols="12" sm="12">
                <label for="">{{ $t("competitors") }}</label>
                <a-booking-url-field
                  v-model="competitor_booking_url_1"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
                <a-booking-url-field
                  v-model="competitor_booking_url_2"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
                <a-booking-url-field
                  v-model="competitor_booking_url_3"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
                <a-booking-url-field
                  v-model="competitor_booking_url_4"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
                <a-booking-url-field
                  v-model="competitor_booking_url_5"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
              </v-col>
            </v-row>
            <v-card-actions class="ps-5 pb-5 justify-center">
              <v-btn
                rounded
                color="secondary"
                :disabled="!configuration_valid"
                @click="setHotelConfiguration"
                :loading="saving_configuration"
                class="pl-5 pr-5 "
                >{{ $t("save") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- MISSING CONFIGURATION DONE -->
    <v-dialog v-model="show_not_configured_done" persistent max-width="400">
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("missing_config_done")
        }}</v-card-title>
        <v-card-text class="text-center justify-center">
          <br /><img
            src="/img/logo/veetal-imagotipo-sma.png"
            alt="loader"
          /><br /><br />
          <p>{{ $t("missing_config_done_message") }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NO HOTELS -->
    <v-dialog v-model="no_hotels" persistent max-width="400">
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("no_hotels")
        }}</v-card-title>
        <v-card-text class="text-center justify-center">
          <br /><img
            src="/img/logo/veetal-imagotipo-sma.png"
            alt="loader"
          /><br /><br />
          <p>{{ $t("no_hotels_message") }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- HOTEL LIST -->
    <v-dialog v-model="show_hotel_list" fullscreen scrollable origin="top left">
      <v-card color="grey lighten-4" class="">
        <v-card-title class="headline justify-center">
          <v-spacer></v-spacer>
          <v-btn
            style="position:absolute; top:13px; right:10px;"
            icon
            @click="show_hotel_list = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="mt-8">
          <hotel-resume></hotel-resume>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SHARE CONTENT -->
    <v-dialog v-model="share_content" fullscreen scrollable origin="top left">
      <v-card :loading="loading_screenshot">
        <v-card-title class="headline justify-center pa-0 ma-0">
          <v-toolbar extended color="grey lighten-3">
            <v-toolbar-title>{{ $t("share") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              style="position:absolute; top:13px; right:10px;"
              icon
              @click="share_content = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <template v-slot:extension>
              <div
                class="d-flex fixed align-center justify-center full-width pb-6 my-n2"
              >
                <v-btn-toggle
                  class="mr-2"
                  rounded
                  mandatory
                  v-model="pencil_width"
                  color="primary"
                  :dense="$vuetify.breakpoint.xsOnly"
                >
                  <v-btn
                    v-for="width in available_pencil_width"
                    :key="width"
                    :value="width"
                    text
                  >
                    <v-avatar :size="width" color="black"></v-avatar>
                  </v-btn>
                </v-btn-toggle>
                <v-btn-toggle
                  rounded
                  mandatory
                  v-model="pencil_color"
                  color="primary"
                  :dense="$vuetify.breakpoint.xsOnly"
                >
                  <v-btn
                    v-for="color in available_pencil_color"
                    :key="color.code"
                    :value="color.code"
                    text
                    :color="color.code"
                  >
                    <v-avatar :size="20" :color="color.code"></v-avatar>
                  </v-btn>
                </v-btn-toggle>
                <v-btn
                  :small="$vuetify.breakpoint.xsOnly"
                  icon
                  @click="clear"
                  class="ml-2"
                  :color="'red'"
                  outlined
                >
                  <v-icon small>mdi-reload</v-icon>
                </v-btn>
              </div>
            </template>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-overlay :value="loading_screenshot">
            <span>{{ $t("generating_image") }}</span>
          </v-overlay>
          <img
            class=" mt-6 elevation-4 pa-3"
            id="image_to_share"
            width="800"
            :src="share_image_src"
            style="display:none;"
          />
          <div
            class="d-flex justify-center"
            style="width=100%;max-width=800px;"
          >
            <canvas ref="canvas" width="100" height="100"></canvas>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions color="grey lighten-3" class="pa-0">
          <v-list class="full-width" color="grey lighten-3">
            <!-- <v-subheader>{{ $t("options") }}...</v-subheader> -->

            <v-list-item @click="email">
              <v-list-item-avatar>
                <v-avatar size="32px" tile>
                  <v-icon color="secondary">mdi-send-outline</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t("share") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="download" color="secondary">
              <v-list-item-avatar>
                <v-avatar size="32px" tile>
                  <v-icon color="secondary">mdi-download</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t("download") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet hide-overlay persistent v-model="test">
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="error" @click="test = !test">
          close
        </v-btn>
        <div class="my-3">
          no_hotels -> {{ no_hotels }} <br />
          no_data -> {{ no_data }} <br />
          show_not_configured -> {{ show_not_configured }} <br />
          show_not_configured_done -> {{ show_not_configured_done }}
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <a-ai-assistant class="mt-16" v-if="show_ai_assistant"></a-ai-assistant>
  </v-app>
</template>
<script>
/* eslint-disable */

import Vue from "vue";
import { mapState, mapGetters } from "vuex";
//import { mapActions } from "vuex";
//import i18n from "@/plugins/i18n";
import hotelResume from "@/components/hotelResume";
import aBookingUrlField from "@/components/aBookingUrlField";
import { fabric } from "fabric";
import aAiAssistant from "@/components/aAiAssistant.vue";

const moment = require("moment");
require("moment/locale/ca");

Vue.use(require("vue-moment"), {
  moment
});

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export default {
  props: {
    source: String
  },
  components: {
    hotelResume,
    aBookingUrlField,
    aAiAssistant
  },
  data: () => ({
    menu_color: "secondary",
    can_edit: false,
    new_order: {},
    //Scrollbar
    ops: {
      vuescroll: {
        mode: "native",
        detectResize: true
      },
      scrollPanel: {
        scrollingX: false
      },
      rail: {},
      bar: {
        background: "#cacaca"
      }
    },
    //Menu
    dialog: false,
    menu_items: [
      {
        icon: "mdi-home-outline",
        visibility: "dashboard_rate",
        literal: "dashboard",
        page: "dashboard",
        mobile: true
      },
      {
        icon: "mdi-home-outline",
        visibility: "dashboard_rms",
        literal: "dashboard",
        page: "dashboard",
        mobile: true
      },
      {
        icon: "mdi-home-outline",
        visibility: "dashboard_rms_hotelsdot",
        literal: "dashboard",
        page: "dashboard",
        mobile: true
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-hand-coin-outline",
        visibility: "rms_hotelsdot",
        literal: "RMS",
        page: "rms",
        mobile: true,
        children: [
          {
            icon: "mdi-strategy",
            visibility: "rms_strategy_hotelsdot",
            literal: "revenue-strategy",
            page: "rms_strategy_hotelsdot"
          },
          {
            icon: "mdi-calendar-end-outline",
            visibility: "rms_sales_hotelsdot",
            literal: "year-sales-report",
            page: "rms_year_sales"
          },
          {
            icon: "mdi-select-compare",
            visibility: "rms_availability_hotelsdot",
            literal: "availability-report",
            page: "rms_availability_hotelsdot"
          },
          {
            icon: "mdi-tag-multiple-outline",
            visibility: "rms_pricing_hotelsdot",
            literal: "pricing-report",
            page: "rms_pricing"
          },
          {
            icon: "mdi-sale-outline",
            visibility: "rms_special_rates_hotelsdot",
            literal: "special-rates",
            page: "rms_special_rates"
          },
          {
            icon: "mdi-hub-outline",
            visibility: "rms_segment_hotelsdot",
            literal: "segments-report",
            page: "rms_segment_report"
          },
          {
            icon: "mdi-hexagon-multiple-outline",
            visibility: "rms_channel_hotelsdot",
            literal: "channels-report",
            page: "rms_channel_report"
          },
          {
            icon: "mdi-sofa-outline",
            visibility: "rms_roomtype_hotelsdot",
            literal: "room-types-report",
            page: "rms_room_types_report"
          },
          // {
          //   icon: "mdi-chart-timeline-variant",
          //   visibility: "rms_prices_evo_hotelsdot",
          //   text: "Evolución de Tarifas",
          //   page: "rms_prices_evo"
          // },
        ]
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-hand-coin-outline",
        visibility: "rms",
        literal: "RMS",
        page: "rms",
        mobile: true,
        children: [
          {
            icon: "mdi-strategy",
            visibility: "rms_strategy",
            literal: "revenue-strategy",
            page: "rms_strategy"
          },
          {
            icon: "mdi-strategy",
            visibility: "rms_strategy_grid",
            literal: "revenue-strategy",
            page: "rms_strategy_grid"
          },
          {
            icon: "mdi-chart-timeline-variant",
            visibility: "rms_sales",
            literal: "revenue-report",
            page: "rms_revenue"
          },
          {
            icon: "mdi-calendar-end-outline",
            visibility: "rms_year_sales",
            literal: "year-sales-report",
            page: "rms_year_sales_report"
          },
          {
            icon: "mdi-calendar-end-outline",
            visibility: "rms_year_sales_grid",
            literal: "year-sales-report",
            page: "rms_year_sales_grid"
          },
          {
            icon: "mdi-select-compare",
            visibility: "rms_availability",
            literal: "availability-report",
            page: "rms_availability"
          },
          {
            icon: "mdi-tag-multiple-outline",
            visibility: "rms_pricing",
            literal: "pricing-report",
            page: "rms_pricing"
          },
          {
            icon: "mdi-sale-outline",
            visibility: "rms_special_rates",
            literal: "special-rates",
            page: "rms_special_rates"
          },
          {
            icon: "mdi-hub-outline",
            visibility: "rms_segment",
            literal: "segments-report",
            page: "rms_segment_report"
          },
          {
            icon: "mdi-hexagon-multiple-outline",
            visibility: "rms_channel",
            literal: "channels-report",
            page: "rms_channel_report"
          },
          {
            icon: "mdi-sofa-outline",
            visibility: "rms_roomtype",
            literal: "room-types-report",
            page: "rms_room_types_report"
          },
          // {
          //   icon: "mdi-chart-timeline-variant",
          //   visibility: "rms_prices_evo",
          //   text: "Evolución de Tarifas",
          //   page: "rms_prices_evo"
          // },
        ]
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-hand-coin-outline",
        visibility: "rms_axel",
        literal: "RMS",
        page: "rms",
        mobile: true,
        children: [
          {
            icon: "mdi-strategy",
            visibility: "rms_strategy_axel",
            literal: "revenue-strategy",
            page: "rms_strategy_axel"
          },
          {
            icon: "mdi-chart-timeline-variant",
            visibility: "rms_sales",
            literal: "revenue-report",
            page: "rms_revenue"
          },
          {
            icon: "mdi-calendar-end-outline",
            visibility: "rms_year_sales_axel",
            literal: "year-sales-report",
            page: "rms_year_sales_axel"
          },
          {
            icon: "mdi-select-compare",
            visibility: "rms_availability",
            literal: "availability-report",
            page: "rms_availability"
          },
          {
            icon: "mdi-tag-multiple-outline",
            visibility: "rms_pricing",
            literal: "pricing-report",
            page: "rms_pricing"
          },
          {
            icon: "mdi-sale-outline",
            visibility: "rms_special_rates",
            literal: "special-rates",
            page: "rms_special_rates"
          },
          {
            icon: "mdi-hub-outline",
            visibility: "rms_segment",
            literal: "segments-report",
            page: "rms_segment_report"
          },
          {
            icon: "mdi-hexagon-multiple-outline",
            visibility: "rms_channel",
            literal: "channels-report",
            page: "rms_channel_report"
          },
          {
            icon: "mdi-sofa-outline",
            visibility: "rms_roomtype",
            literal: "room-types-report",
            page: "rms_room_types_report"
          },
          // {
          //   icon: "mdi-chart-timeline-variant",
          //   visibility: "rms_prices_evo",
          //   text: "Evolución de Tarifas",
          //   page: "rms_prices_evo"
          // },
        ]
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-hand-coin-outline",
        visibility: "rms_clicktotravel",
        literal: "RMS",
        page: "rms",
        mobile: true,
        children: [
          {
            icon: "mdi-strategy",
            visibility: "rms_strategy_clicktotravel",
            literal: "revenue-strategy",
            page: "rms_strategy_clicktotravel"
          },
          {
            icon: "mdi-calendar-end-outline",
            visibility: "rms_year_sales",
            literal: "year-sales-report",
            page: "rms_year_sales_report"
          },
          {
            icon: "mdi-select-compare",
            visibility: "rms_availability",
            literal: "availability-report",
            page: "rms_availability"
          },
          {
            icon: "mdi-tag-multiple-outline",
            visibility: "rms_pricing",
            literal: "pricing-report",
            page: "rms_pricing"
          },
          {
            icon: "mdi-hub-outline",
            visibility: "rms_segment",
            literal: "segments-report",
            page: "rms_segment_report"
          },
          {
            icon: "mdi-hexagon-multiple-outline",
            visibility: "rms_channel",
            literal: "channels-report",
            page: "rms_channel_report"
          },
          {
            icon: "mdi-sofa-outline",
            visibility: "rms_roomtype",
            literal: "room-types-report",
            page: "rms_room_types_report"
          },
        ]
      },
      {
        icon: "mdi-bed-king-outline",
        visibility: "rate",
        literal: "rate",
        page: "rate",
        mobile: true
      },
      {
        icon: "mdi-google",
        visibility: "parity",
        literal: "parity",
        page: "parity",
        mobile: true
      },
      {
        icon: "mdi-sitemap-outline",
        visibility: "channelmanager",
        literal: "channel_manager",
        page: "channel_manager",
        mobile: true
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-comment-multiple-outline",
        literal: "reputation",
        page: "reputation",
        visibility: "reputation",
        mobile: true,
        children: [
          {
            icon: "mdi-view-dashboard-outline",
            text: "overview",
            literal: "overview",
            visibility: "reputation",
            page: "reputation"
          },
          {
            icon: "mdi-alpha-g-circle-outline",
            text: "Google Hotel Ads",
            page: "ota",
            page_params: { ota: "googlebusiness" },
            visibility: "reputation_googlehotelads"
          },
          {
            icon: "mdi-alpha-t-circle-outline",
            text: "Trip Advisor",
            page: "ota",
            page_params: { ota: "tripadvisor" },
            visibility: "reputation_tripadvisor"
          },
          {
            icon: "mdi-alpha-b-circle-outline",
            text: "Booking",
            page: "ota",
            page_params: { ota: "booking" },
            visibility: "reputation_booking"
          },
          {
            icon: "mdi-alpha-e-circle-outline",
            text: "Expedia",
            page: "ota",
            page_params: { ota: "expedia" },
            visibility: "reputation_expedia"
          }
          /*
          { icon: "mdi-pencil-circle-outline", text: "Encuestas", page: "survey", visibility: "has_survey", }*/
        ]
      },
      {
        icon: "mdi-bell-outline",
        literal: "alerts",
        page: "highlights",
        mobile: false
      },
      {
        icon: "mdi-rocket-launch-outline",
        literal: "automations",
        page: "automations",
        visibility: ["automations"],
        mobile: false
      },
      {
        "icon-left": "mdi-trending-up",
        "icon-left-alt": "mdi-trending-up",
        "icon-right": "keyboard_arrow_down",
        "icon-right-alt": "keyboard_arrow_down",
        icon: "mdi-cog-outline",
        literal: "settings",
        page: "settings",
        mobile: false,
        visibility: ["settings"],
        children: [
          {
            icon: "mdi-view-dashboard-outline",
            literal: "overview",
            page: "settings",
            visibility: "hotelmanagement"
          },
          {
            icon: "mdi-view-dashboard-edit-outline",
            literal: "customer-contracted-sections",
            page: "customer",
            visibility: "customermanagement"
          },
          {
            icon: "mdi-bed-outline",
            literal: "hotels",
            page: "hotelmanagement",
            visibility: "hotelmanagement"
          },
          {
            icon: "mdi-account-group-outline",
            literal: "team",
            page: "team",
            visibility: "usermanagement"
          },
          {
            icon: "mdi-calendar-range-outline",
            literal: "hotel-calendar",
            page: "calendar",
            visibility: "calendarmanagement"
          },
          {
            icon: "mdi-calendar-star",
            literal: "event-management",
            page: "event-management",
            visibility: "eventsmanagement"
          },
          {
            icon: "mdi-hub-outline",
            literal: "segments-channels",
            page: "segments-channels",
            visibility: "segmentschannels"
          },
          {
            icon: "mdi-api",
            literal: "api_status",
            page: "api-status",
            visibility: "apistatus"
          },
          {
            icon: "mdi-barcode-scan",
            literal: "trial",
            page: "trial",
            visibility: "trialmanagement"
          },
          {
            icon: "mdi-account-question",
            literal: "web_requests",
            page: "requests",
            visibility: "requestsmanagement"
          },
          {
            icon: "mdi-sofa-outline",
            literal: "room_types",
            page: "room-types",
            visibility: "roomtypemanagement"
          },
          {
            icon: "mdi-calculator-variant-outline",
            literal: "budget",
            page: "budget",
            visibility: "budget"
          },
          {
            icon: "mdi-home-analytics",
            literal: "metrics",
            page: "metrics",
            visibility: "metrics"
          },
          {
            icon: "mdi-database-import-outline",
            literal: "revenue_import",
            page: "revenue-import",
            visibility: "revenue_import_manual"
          }
        ]
      }
      // ]
    ],
    //Current user
    saving: false,
    tab: 0,
    show_current_user: false,
    valid: true,
    password_valid: true,
    new_password: "",
    new_password2: "",
    //Disconnect
    show_disconnect: false,
    // Share
    available_pencil_color: [
      {
        code: "orange",
        color: "orange"
      },
      {
        code: "red",
        color: "red"
      },
      {
        code: "blue",
        color: "blue"
      },
      {
        code: "green",
        color: "green"
      },
      {
        code: "black",
        color: "black"
      }
    ],
    pencil_color: "red",
    available_pencil_width: [4, 8, 15, 20],
    pencil_width: 15,
    // Configure popup
    configuration_valid: false,
    hotel_booking_url: "",
    competitor_booking_url_1: "",
    competitor_booking_url_2: "",
    competitor_booking_url_3: "",
    competitor_booking_url_4: "",
    competitor_booking_url_5: "",
    saving_configuration: false,
    show_not_configured_done: false,
    test: false
  }),
  computed: {
    loading_screenshot: {
      get() {
        return this.$store.state.loading_screenshot;
      },
      set(value) {
        this.$store.dispatch("setLoadingScreenshot", value);
      }
    },
    share_image_src: {
      get() {
        return this.$store.state.share_image_src;
      },
      set(value) {
        this.$store.dispatch("setShareImageSrc", value);
      }
    },
    share_image_width: {
      get() {
        return this.$store.state.share_image_width;
      },
      set(value) {
        this.$store.dispatch("setShareImageWidth", value);
      }
    },
    share_image_height: {
      get() {
        return this.$store.state.share_image_height;
      },
      set(value) {
        this.$store.dispatch("setShareImageHeight", value);
      }
    },
    share_content: {
      get() {
        return this.$store.state.share_content;
      },
      set(value) {
        this.$store.dispatch("setShareContent", value);
      }
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      }
    },
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set(value) {
        this.$store.dispatch("setMini", value);
      }
    },
    show_hotel_list: {
      get() {
        return this.$store.state.hotel.show_hotel_list;
      },
      set(value) {
        this.$store.dispatch("setShowHotelList", value);
      }
    },
    selector_filters_to_watch() {
      return [
        this.current_hotel ? this.current_hotel.id : 0,
        this.current_section,
        this.vs_filter_selected_option,
        this.vs_hotel_selected
      ].join("_");
    },
    repeatPasswordRules() {
      return [
        v => !!v || this.$t("cant_be_empty"),
        v => (v && v.length >= 8) || this.$t("at_least_8_character"),
        v => v == this.new_password || this.$t("no_equal")
      ];
    },
    userItems() {

      return this.current_user && this.current_hotel
        ? this.checkVisibility(this.menu_items)
              .map(item => {
                if (item.children) {
                  item.children = this.checkVisibility(item.children);
                }
                return item
              })
          : [];
    },
    settingsItems() {
      return this.current_user
        ? this.userItems.filter(item => item.page == "settings")
        : [];
    },
    menuItems() {
      return this.current_user
        ? this.userItems.filter(item => item.page != "settings")
        : [];
    },
    mobileItems() {
      return this.menuItems ? this.menuItems.filter(item => item.mobile) : [];
    },
    mobileSettingsItems() {
      return this.settingsItems
        ? this.settingsItems.filter(item => item.mobile)
        : [];
    },
    hiddenMobileItems() {
      return this.menuItems
        ? this.menuItems.filter(function(item) {
            return !item.mobile;
          })
        : [];
    },
    isVersionPage() {
      return this.$route.meta.menu == "version" ? true : false;
    },
    no_data() {
      return this.current_hotel && this.current_hotel.last_import_date.dashboard === null;
    },
    show_no_data() {
      return (
        this.no_data &&
        !this.show_not_configured &&
        !this.show_not_configured_done
      );
    },
    show_not_configured() {
      return (
        this.no_data &&
        this.current_hotel &&
        this.current_hotel.trial &&
        (this.current_hotel.trial.hotel_booking_url == null ||
          this.current_hotel.trial.competitors_urls.length == 0)
      );
    },
    show_ai_assistant() {
      return this.current_user && this.current_user.permissions && this.current_user.permissions.veetal_assistant;
    },
    selected_period: {
      get() {
        return this.$store.state.selected_period;
      },
      set(value) {
        this.$store.dispatch("setSelectedPeriod", value);
      }
    },
    selected_year: {
      get() {
        return this.$store.state.selected_year;
      },
      set(value) {
        this.$store.dispatch("setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.state.selected_month;
      },
      set(value) {
        this.$store.dispatch("setSelectedMonth", value);
      }
    },
    period_filter_selected_option: {
      get() {
        return this.$store.state.period_filter_selected_option;
      },
      set(value) {
        this.$store.dispatch("setPeriodFilterSelectedOption", value);
      }
    },
    period_filter_selected_year: {
      get() {
        return this.$store.state.period_filter_selected_year;
      },
      set(value) {
        this.$store.dispatch("setPeriodFilterSelectedYear", value);
      }
    },
    ...mapState({
      chain: state => state.chain.chain,
      snackbar: state => state.snackbar,
      users: state => state.user.users,
      user: state => state.user.user,
      language_options: state => state.language.language_options,
      vs_filter_selected_option: state => state.vs_filter_selected_option,
      vs_hotel_selected: state => state.vs_hotel_selected,
      available_months: state => state.hotel.hotel_available_months,
      competitors: state => state.rate.competitors,
      webs: state => state.parity.webs,
      no_hotels: state => state.hotel.no_hotels
    }),
    ...mapGetters({
      name_rules: "name_rules",
      email_rules: "email_rules",
      date_rules: "date_rules",
      password_rules: "password_rules",
      booking_rules: "booking_rules",
      getUserById: "getUserById",
      current_user: "current_user",
      dialogHeight: "dialogHeight",
      authenticated: "authenticated",
      current_hotel: "current_hotel",
      current_section: "current_section"
    })
  },
  watch: {
    share_image_src(newValue, oldValue) {
      var vm = this;
      Vue.nextTick(function() {
        if (newValue && newValue !== oldValue) {
          setTimeout(function() {
            vm.loading_screenshot = false;
            vm.addImageToCanvas();
          }, 1000);
        }
      });
    },
    selector_filters_to_watch() {
      this.$store.dispatch("getAvailableMonths");
    },
    pencil_color() {
      this.changePencilColor(this.pencil_color);
    },
    pencil_width() {
      this.changePencilWidth(this.pencil_width);
    },
    current_hotel() {
      if (this.current_hotel && this.current_hotel.trial) {
        this.hotel_booking_url = this.current_hotel.trial.hotel_booking_url;
        for (let i = 0; i < 5; i++) {
          this[
            `competitor_booking_url_${i + 1}`
          ] = this.current_hotel.trial.competitors_urls[i];
        }
      }
    }
  },
  methods: {
    resizeWindow() {
      this.$store.dispatch("setAppWidth", window.innerWidth);
    },
    addImageToCanvas() {
      this.initShareApp();
      var imgElement = document.getElementById("image_to_share");
      var imgInstance = new fabric.Image(imgElement, function(oImg) {});
      var max_width = window.innerWidthHeight - 150;
      var width_to_set = this.share_image_width;
      if (this.share_image_width > max_width) {
        width_to_set = max_width;
      }

      imgInstance.scaleToWidth(width_to_set);
      this.canvas.setWidth(width_to_set);
      this.canvas.setHeight(this.share_image_height);
      this.canvas.clear();
      this.canvas.add(imgInstance);
      this.canvas.renderAll();
    },
    initShareApp() {
      if (!this.canvas) {
        const ref = this.$refs.canvas;
        this.canvas = new fabric.Canvas(ref, {
          isDrawingMode: true
        });
        this.changePencilColor(this.pencil_color);
        this.changePencilWidth(this.pencil_width);
      }
    },
    changePencilWidth(width) {
      this.canvas.freeDrawingBrush.width = parseInt(width, 10) || 1;
    },
    changePencilColor(color) {
      var brush = this.canvas.freeDrawingBrush;
      brush.color = color;
      if (brush.getPatternSrc) {
        brush.source = brush.getPatternSrc.call(brush);
      }
    },
    clear() {
      this.addImageToCanvas();
    },
    download() {
      var a = document.getElementById("image_to_download");
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      // a.href = this.share_image_src;
      a.href = this.canvas.toDataURL("image/png");
      a.download = "image.png";
      a.click();
      this.share_content = false;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("downloaded_image"),
        color: "secondary"
      });
    },
    share() {
      this.share_content = false;
    },
    // sendEmail(){
    //   // &body=Hi,I found this website and thought you might like it http://www.geocities.com/wowhtml/
    //   document.location.href= "mailto:?subject=The%20subject%20of%20the%20mail&body=<img src='" + this.share_image_src + "'/>";
    // },
    email() {
      // var file = dataURLtoFile(this.share_image_src, "image.png");
      var file = dataURLtoFile(this.canvas.toDataURL("image/png"), "image.png");
      var filesArray = [file];

      // if (navigator.canShare && navigator.canShare({ files: filesArray })) {
      navigator
        .share({
          files: filesArray,
          title:
            this.current_user.name +
            " " +
            this.current_user.surnames +
            " " +
            this.$t("is_sharing_with_you"),
          text: this.$t("enjoy_veetal")
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("shared_content"),
            color: "accent"
          });
        })
        .catch(error => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("error_while_sharing_email"),
            description: error,
            color: "error"
          });
        });

      this.share_content = false;
    },
    getParentIcon(item) {
      return item.icon;
    },
    getIcon(item) {
      return this.isCurrentPage(item)
        ? item.icon.split("-outline")[0]
        : item.icon;
    },
    clickMenu(item) {
      this.$router
        .push({
          name: item.page,
          params: item.page_params ? item.page_params : {}
        })
        .catch(err => {});
      this.closeMenu();
    },
    closeMenu() {
      if (
        !(this.$vuetify.breakpoint.lgOnly || this.$vuetify.breakpoint.xlOnly)
      ) {
        this.drawer = false;
      }
    },
    showHotel() {
      this.show_hotel_list = true;
    },
    showCurrentUser() {
      this.closeMenu();
      this.show_current_user = true;
      this.$store.dispatch("fetchUser", this.current_user.id);

      if (this.$refs.password_form) {
        this.$refs.password_form.reset();
      }
    },
    repeat_pwd_validate() {
      this.$refs.new_password2.validate();
    },
    changeUserData() {
      if (this.valid) {
        this.saving = true;
        this.$store
          .dispatch("modifyUser", this.user)
          .then(() => {
            this.user.token = this.current_user.token;
            this.saving = false;
            this.$store.dispatch("updateCredentials", this.user).then(() => {
              this.$store.dispatch("setSnackbar", {
                title: this.$t("modified_user"),
                color: "secondary"
              });
            });
          })
          .catch(() => {
            this.saving = false;
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
          });
      }
    },
    changeUserPassword() {
      if (this.password_valid) {
        this.saving = true;

        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_password"),
          color: "secondary"
        });
        const data = {
          id: this.current_user.id,
          new_password: this.new_password
        };

        this.show_current_user = false;
        this.$store
          .dispatch("changeUserPassword", data)
          .then(() => {
            this.saving = false;
            this.new_password = "";
            this.new_password2 = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_password"),
              text: this.$t("modified_password_ok"),
              color: "secondary"
            });
          })
          .catch(() => {
            this.saving = false;
            this.new_password = "";
            this.new_password2 = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
          });
      }
    },
    showDisconnect() {
      this.show_disconnect = true;
    },
    disconnect() {
      this.$store.dispatch("setLoginLayout");
      //window.Tawk_API.hideWidget();
      this.$store.dispatch("setUnauthenticated");
      this.$router.push({
        name: "login"
      });
    },
    isCurrentPage(item) {
      let check_params = true;
      if (item.page_params) {
        // Must check page params
        for (let key in item.page_params) {
          if (this.$route.params[key] !== item.page_params[key]) {
            check_params = false;
          }
        }
      }
      return item.page == this.$route.meta.menu && check_params ? true : false;
    },
    isCurrentPageParent(item) {
      return item.children.filter(child => child.page == this.$route.meta.menu)
        .length > 0
        ? true
        : false;
    },
    initMainFilter() {
      this.selected_period = "month";
      this.period_filter_selected_option = 1;
      this.period_filter_selected_year = Vue.moment()
        .subtract(1, "days")
        .format("Y");
      this.selected_year = Vue.moment()
        .subtract(1, "days")
        .format("Y");
      this.selected_month = parseInt(
        Vue.moment()
          .subtract(1, "days")
          .format("M")
      );
    },
    setHotelConfiguration() {
      if (this.configuration_valid) {
        this.saving_configuration = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_configuration"),
          color: "secondary"
        });
        const data = {
          id: this.current_hotel.trial.id,
          hotel_booking_url: this.hotel_booking_url,
          competitor_booking_urls: [
            this.competitor_booking_url_1,
            this.competitor_booking_url_2,
            this.competitor_booking_url_3,
            this.competitor_booking_url_4,
            this.competitor_booking_url_5
          ]
        };

        this.$store
          .dispatch("changeTrialConfiguration", data)
          .then(data => {
            this.saving_configuration = false;
            this.current_hotel.trial.hotel_booking_url = this.hotel_booking_url;
            this.current_hotel.trial.competitors_urls = [
              this.competitor_booking_url_1,
              this.competitor_booking_url_2,
              this.competitor_booking_url_3,
              this.competitor_booking_url_4,
              this.competitor_booking_url_5
            ];
            this.show_not_configured_done = true;

            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_configuration"),
              text: this.$t("modified_configuration_ok", {
                hotel_name: this.current_hotel.name
              }),
              color: "secondary",
              timeout: 5000
            });
          })
          .catch(() => {
            this.saving_configuration = false;
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
          });
      }
    },
    cleanUrl(url) {
      return url
        ? url
            .replace("http://", "")
            .replace("https://", "")
            .split("?")[0]
        : "";
    },
    checkVisibility(items) {
      return items
          .filter(item =>
              !item.visibility || this.current_user.permissions[item.visibility]
                  ? item
                  : null
          )
          .filter(item =>
              !item.visibilities ||
              item.visibilities.some(
                  visibility => this.current_user.permissions[visibility]
              )
                  ? item
                  : null
          )
          .filter(item =>
              !item.visibility || this.current_hotel.permissions[item.visibility] || (item.page !== 'dashboard' && this.current_user.superuser)
                  ? item
                  : null
          )
    }
  },
  mounted() {
    this.$store.dispatch("fetchChain");
    this.$store.dispatch("fetchHotels");
    this.$store.dispatch("fetchUsers").then(() => {
      var user = this.getUserById(this.current_user.id);
      user.token = this.current_user.token;
      this.$store.dispatch("updateCredentials", user);
    });

    // Init Main Filter variables
    this.initMainFilter();

    // Init window width
    window.addEventListener("resize", this.resizeWindow);
    this.$store.dispatch("setAppWidth", window.innerWidth);
  }
};
</script>
<style lang="scss">
.v-application{

  /* MAIN STYLES */

  body,
  html,
  .title,
  .body-1,
  .body-2,
  .display-1,
  .display-2,
  .subtitle-1,
  .subtitle-2,
  .headline,
  .overline {
    letter-spacing: 0.3px !important;
  }
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    font-weight: 600 !important;
  }

  .theme--light.v-card > .v-card__text,
  .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.9);
  }

  .full-width {
    width: 100% !important;
  }

  /* LAYOUT */
  header {
    padding: 0px !important;
    /* box-shadow: 0 0 0 !important; */
  }
  header.v-app-bar.v-app-bar--fixed {
    z-index: 200;
  }
  header.scrolled {
    padding: 0px !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  #page {
    padding-top: 0px !important;
    background: #fdfdfd !important;
  }
  header.v-toolbar {
    background-size: 100%;
  }
  /* .top-img .v-image__image {
    background-size: 100% !important;
  } */

  /* DRAWER */

  /* MOBILE */
  .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
    max-height: 100% !important;
  }
  .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile.v-navigation-drawer--open
    .menu_first_node {
    position: fixed;
    background: #ffffff !important;
    top: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 0px 9px -3px #777;
  }
  .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile.v-navigation-drawer--open
    .menu_second_node {
    margin-top: 55px !important;
  }

  /* TABLET */
  .tablet-drawer-button {
    position: absolute !important;
    left: 0px;
    top: 0px;
    z-index: 2;
    color: #ffffff !important;
    margin: 5px;
  }
  /* INDIVIDUAL NODES */
  .theme--light
    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--light
    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    > .v-list-item__action
    > .v-icon {
    color: #111111;
  }
  /* GROUP HEADING NODES */
  .theme--light
    .v-list-group:not(.v-list-group--active):not(.v-list-group--disabled),
  .theme--light
    .v-list-group:not(.v-list-group--active):not(.v-list-group--disabled)
    > .v-list-group__header
    > .v-list-item__icon
    > .v-icon {
    color: #111111;
  }
  .v-list-item--dense .v-list-item__icon,
  .v-list--dense .v-list-item .v-list-item__icon {
    margin: 12px 10px 12px 0px !important;
  }

  .v-list-group--active {
    background: #fafafa;
  }
  /* GROUP CHILD NODES */
  .theme--light
    .v-list-group
    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--light
    .v-list-group
    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    > .v-list-item__action
    > .v-icon {
    color: #999999;
  }

  /* BOTTOM INDIVIDUAL NODES */
  .theme--light
    .bottom-list-item.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--light
    .bottom-list-item.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    > .v-list-item__action
    > .v-icon {
    color: #777777 !important;
  }

  #zingchart-vue-0-license-text,
  #zingchart-vue-1-license-text,
  #zingchart-vue-2-license-text,
  #zingchart-vue-3-license-text,
  #zingchart-vue-0-menu,
  #zingchart-vue-1-menu,
  #zingchart-vue-2-menu,
  #zingchart-vue-3-menu,
  #zingchart-vue-4-menu,
  div[id$="-license-text"]{
    display: none !important;
  }
  .v-tab {
    min-width: 70px;
    letter-spacing: 0.05em !important;
  }

  /* Z-INDEX */
  /* Barra superior página */
  header.v-app-bar.v-app-bar--fixed {
    z-index: 300 !important;
  }
  /* Calendario */
  .v-menu__content:not(.v-menu__content--fixed) {
    z-index: 400 !important;
  }
  /* Barra menú inferior xs */
  .v-bottom-navigation {
    z-index: 400 !important;
  }
  /* Menú (xs desplegado, md desplegado, lg plegado/desplegado) */
  .v-navigation-drawer--fixed {
    z-index: 500 !important;
  }
  /* Menú (xs, md, lg) */
  .v-navigation-drawer {
    z-index: 500 !important;
  }
  /* Capa negra fondo */
  .v-overlay {
    z-index: 600 !important;
  }
  /* Popup + Menú md desplegado  */
  .v-dialog,
  .v-dialog__content,
  .v-navigation-drawer--fixed.v-navigation-drawer--is-mobile {
    z-index: 700 !important;
  }
  @media (max-width: 600px) {
    .v-overlay {
      z-index: 400 !important;
    }
  }

  .v-dialog .close_dialog {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 97% !important;
  }
  .v-navigation-drawer--mini-variant .v-list-item {
    justify-content: normal !important;
  }
  .pull-bottom {
    position: fixed !important;
    bottom: 0;
    width: 100%;
    background: #ffffff !important;
  }
  .v-list-item__action,
  .v-list-item__icon {
    min-width: 46px !important;
    justify-content: center !important;
  }

  .v-application--is-ltr .v-list-item__action:first-child,
  .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 10px !important;
  }
  .v-application--is-ltr .v-list-item__avatar:first-child {
    margin-right: 18px !important;
    margin-left: 5px !important;
  }
  .v-application--is-ltr
    button.v-btn--fab.v-size--small.v-list-item__avatar:first-child {
    margin-right: 19px !important;
    margin-left: 4px !important;
    width: 40px !important;
    height: 40px !important;
  }

  /* INPUT LABELS */
  .v-application--is-ltr .v-text-field .v-label {
    font-size: 15px;
    font-weight: 400;
    /* color: #999 !important; */
    /* text-transform: uppercase; */
  }

  /* Datatable-styles  */
  .a-datatable-row:hover {
    background-color: #e4e8f5 !important;
  }
  .a-datatable-row:hover > td:first-child {
    color: #1c46dd !important;
  }
  .a-datatable-row > td:first-child {
    color: #444 !important;
    font-weight: 600 !important;
    padding-left: 5px !important;
  }
  .a-datatable-row > td {
    cursor: pointer;
  }
  .v-data-table-header > tr > th:first-child {
    padding-left: 5px !important;
  }
  .v-data-table-header th.sortable {
    padding-right: 0 !important; /* hacks arrow disalignment */
    text-transform: uppercase;
  }

  .v-list-item:hover:not(.no-click) {
    background: #e4e8f5 !important;
  }

  /* SCREENSHOT */
  .loading_screenshot #page {
    padding-left: 0px !important;
    background-color: #f5f5f5 !important;
  }
  .loading_screenshot .a-card {
    /* border: 1px solid #cacaca !important; */
  }
  .loading_screenshot .v-navigation-drawer {
    display: none !important;
  }
  .loading_screenshot #main_header {
    left: 0px !important;
  }

  /* ZINGCHARTS */

  #zingchart-vue-7-license-text {
    display: none !important;
  }


  /* NO DATA */
  @-webkit-keyframes toColor {
    0% {
      filter: grayscale(100%);
    }
    10% {
      filter: grayscale(75%);
    }
    20% {
      filter: grayscale(50%);
    }
    30% {
      filter: grayscale(35%);
    }
    40% {
      filter: grayscale(20%);
    }
    50% {
      filter: grayscale(5%);
    }
    60% {
      filter: grayscale(0);
    }
    70% {
      filter: grayscale(0);
    }
    80% {
      filter: grayscale(0);
    }
    85% {
      filter: grayscale(25%);
    }
    90% {
      filter: grayscale(50%);
    }
    95% {
      filter: grayscale(75%);
    }
    to {
      filter: grayscale(100%);
    }
  }
  @keyframes toColor {
    0% {
      filter: grayscale(100%);
    }
    10% {
      filter: grayscale(75%);
    }
    20% {
      filter: grayscale(50%);
    }
    30% {
      filter: grayscale(35%);
    }
    40% {
      filter: grayscale(20%);
    }
    50% {
      filter: grayscale(5%);
    }
    60% {
      filter: grayscale(0);
    }
    70% {
      filter: grayscale(0);
    }
    80% {
      filter: grayscale(0);
    }
    85% {
      filter: grayscale(25%);
    }
    90% {
      filter: grayscale(50%);
    }
    95% {
      filter: grayscale(75%);
    }
    to {
      filter: grayscale(100%);
    }
  }
  .loading {
    -webkit-animation: toColor 2s;
    animation: toColor 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  /*Parity Styles */
  .primary_bg {
    background-color: rgba(38, 80, 177, 0.1) !important;
  }
  .orange_bg {
    background-color: rgba(230, 152, 18, 0.1) !important;
  }
  .red_bg {
    background-color: rgba(147, 52, 74, 0.1) !important;
  }
  .green_bg {
    background-color: rgba(49, 173, 81, 0.1) !important;
  }
  .teal_bg {
    background-color: rgba(56, 117, 71, 0.1) !important;
  }

  /* Pulse Animation */
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4);
      box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0);
      box-shadow: 0 0 0 10px rgba(21, 118, 199, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0);
      box-shadow: 0 0 0 0 rgba(21, 118, 199, 0);
    }
  }
}
/* Datatable titles and search on mobile*/
@media (max-width: 600px) {
    .v-card__title {
      display: block !important;
      font-size: 1rem;
    }
    .v-dialog .v-card__title {
      display: flex !important;
      font-size: 1rem;
    }
    .v-bottom-navigation
      .v-item-group.v-bottom-navigation
      .v-btn:not(.v-btn--active)
      .v-btn__content
      .v-icon {
      color: black;
    }
    .v-btn:not(.v-btn--round).v-size--default {
      min-width: 46px !important;
      padding: 0 2px !important;
    }
    .container--fluid {
      padding: 0 !important;
    }
    .v-data-footer__select {
      display: none !important;
    }
    .v-data-footer {
      padding: 15px 0 0px 0;
    }

    .v-data-table td,
    .v-data-table th {
      padding: 0 5px !important;
    }
    /* Important for bottom-navigation */
    .v-content__wrap {
      overflow: hidden !important;
    }
    html {
      overflow-x: hidden !important;
      width: 100%;
    }
    body {
      position: relative !important;
      overflow-x: hidden !important;
      width: 100%;
      max-width: 100%;
    }

    /* Bottom navigation */
    .v-item-group.v-bottom-navigation .v-btn {
      min-width: 65px !important;
    }
  }

</style>
