var numeral = require("numeral");
import Vue from "vue";
import i18n from "@/plugins/i18n";
import { get } from "jquery";

var row_style =
  "margin-bottom: 5px; text-align:left; color: #333333; font-size:14px; font-weight:400;font-family:'Roboto Condensed';line-height:16px;";
var current_row_style =
  "text-align:left; color: #333333; font-size:14px; font-weight:700;font-family:'Roboto Condensed';line-height:16px;";

/**
 * Function to get a list of subscribed modules from this component (in the property 'my_subscribed_modules')
 * and the subscribed_modules of its children
 * @param {VueComponent} component The component to get the subscribed modules from and its childs
 */
export function subscribedModules(component) {
  let subscribed_modules = [];
  for (let child in component.$refs) {
    const child_subscribed_modules = component.$refs[child].subscribed_modules;
    if (child_subscribed_modules !== undefined) {
      subscribed_modules = [...subscribed_modules, ...child_subscribed_modules];
    }
  }
  component.subscribed_modules = [
    ...subscribed_modules,
    ...component.my_subscribed_modules
  ].filter((value, index, self) => self.indexOf(value) === index);
}

export function status(component) {
  if (
    component.subscribed_modules === undefined ||
    component.subscribed_modules.length === 0
  ) {
    // If the component does not use any data
    return 2;
  }
  let statuses = [];
  component.subscribed_modules.forEach(module => {
    let parts = module.split("_");
    let module_name = parts.shift();
    let module_data = parts.join("_");
    statuses.push(component.$store.state[module_name][module_data + "_status"]);
  });
  if (Math.max(...statuses) === 0) {
    // If all not loaded, then not loaded
    return 0;
  }
  if (Math.min(...statuses) === 2) {
    // If all loaded, then loaded
    return 2;
  }
  // Otherwise, loading
  return 1;
}

export function getPercent(done, total) {
  if (!done || isNaN(done) || done == 0) {
    return 0;
  }
  if (!total || isNaN(total) || total == 0) {
    return null;
  }
  return parseFloat(((done / total) * 100).toFixed(2));
}

export function getIncrement(prev, curr, sign = 1) {
  let increments = null;
  if (
    prev !== null &&
    prev !== undefined &&
    curr !== null &&
    curr !== undefined
  ) {
    let old_value = parseFloat(prev);
    let new_value = parseFloat(curr);
    let increment =
      new_value === 0.0
        ? old_value === 0.0
          ? 0
          : -100
        : old_value === 0.0
        ? 100
        : (new_value / old_value - 1) * 100;
    increments = increment * (old_value >= 0 ? sign : sign * -1);
  }
  return increments;
}

export function hackSvg() {
  // Hack ellipses
  var ep_circles = document.querySelectorAll(".ep-svg .ep-circle");
  ep_circles.forEach(function(item) {
    item.style.transformOrigin = "50% 50%";
  });

  // Circular progress vuetify
  var v_progress_circular = document.querySelectorAll(
    ".v-progress-circular > svg"
  );
  v_progress_circular.forEach(function(item) {
    item.setAttribute(
      "width",
      parseInt(item.parentElement.style.width.split("px")[0]) - 5
    );
    item.setAttribute(
      "height",
      parseInt(item.parentElement.style.width.split("px")[0]) - 4
    );
    item.style.width = null;
    item.style.height = null;
    item.style.transform = "rotate(-90deg)";
    item.style.margin = "auto";
    item.style.padding = "0";
    item.style.position = "absolute";
    item.style.top = "0";
    item.style.bottom = "0";
    item.style.left = "0";
    item.style.right = "0";
    item.style.zIndex = "0";
  });

  var v_progress_circular_under = document.querySelectorAll(
    ".v-progress-circular > svg .v-progress-circular__underlay"
  );
  v_progress_circular_under.forEach(function(item) {
    item.style.stroke = "rgba(158, 158, 158, 0.4)";
    item.style.zIndex = "1";
  });
  var v_progress_circular_over = document.querySelectorAll(
    ".v-progress-circular > svg .v-progress-circular__overlay"
  );
  v_progress_circular_over.forEach(function(item) {
    item.style.stroke = "#1576c7";
    item.style.zIndex = "2";
  });
}

export function format_kpi_value(v, kpi_type, locale, shorten = false) {
  if (!v && v !== 0) {
    return "-";
  }
  if (Object.keys(locale).length == 0) {
    // Locale not initialized
    return v;
  }

  numeral.locale(locale.iso);
  const suffix = shorten ? "a" : "";

  // Depending on kpi type
  var val = v;
  switch (kpi_type) {
    case "number":
      val = numeral(v).format(locale.number_format + suffix);
      break;
    case "number1":
      val = numeral(v).format(locale.number1_format + suffix);
      break;
    case "number0":
      val = numeral(v).format(locale.number0_format + suffix);
      break;
    case "percent":
      val =
        numeral(v).format(locale.percent_format + suffix) +
        "<span class='symbol'>%</span>";
      break;
    case "percent1":
      val =
        numeral(v).format(locale.percent1_format + suffix) +
        "<span class='symbol'>%</span>";
      break;
    case "percent0":
      val =
        numeral(v).format(locale.percent0_format + suffix) +
        "<span class='symbol'>%</span>";
      break;
    case "money":
      val =
        locale.money_pre_symbol +
        numeral(v).format(locale.number_format + suffix) +
        "<span class='symbol'>" +
        locale.money_post_symbol +
        "</span>";
      break;
    case "money1":
      val =
        locale.money_pre_symbol +
        numeral(v).format(locale.number1_format + suffix) +
        "<span class='symbol'>" +
        locale.money_post_symbol +
        "</span>";
      break;
    case "money0":
      val =
        locale.money_pre_symbol +
        numeral(v).format(locale.number0_format + suffix) +
        "<span class='symbol'>" +
        locale.money_post_symbol +
        "</span>";
      break;
    case "date":
      val = Vue.moment(v).format(locale.date_format);
      break;
  }

  return val;
}
export function formatMoneyPlain(value, locale) {
  return (
    locale.money_pre_symbol +
    numeral(value).format(locale.number_format) +
    "" +
    locale.money_post_symbol
  );
}
export function formatPercentPlain(value, locale) {
  return numeral(value).format(locale.percent_format) + "%";
}
export function cfl(string) {
  return capitalizeFirstLetter(string);
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function generateWebPrice(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getParityColor(web_price, ota_price) {
  // Cases:
  //            95%         100%         105%
  // ------------|------------|------------|---------
  //   agressive  conservative  misaligned  expensive
  //    primary      green        orange      red

  if (
    web_price == null ||
    ota_price == null ||
    web_price == 0 ||
    ota_price == 0
  ) {
    return "";
  }

  const offset_max = 105;
  const offset_min = 95;
  const difference_avg = getParity(web_price, ota_price);
  let color = "green";

  if (difference_avg !== 100) {
    if (difference_avg < offset_min) {
      color = "primary";
    }
    if (difference_avg > 100 && difference_avg <= offset_max) {
      color = "orange";
    }
    if (difference_avg > offset_max) {
      color = "red";
    }
  }
  return color;
}
export function getChannelParitySeverity(channel_price, hotel_price) {
  // Cases:
  //            95%          100%         110%
  // ------------|------------|------------|---------
  //   agressive  conservative  misaligned  expensive
  //    ok          ok            small      critical

  if (
    hotel_price == null ||
    channel_price == null ||
    hotel_price == 0 ||
    channel_price == 0
  ) {
    return "";
  }

  const offset_max = 110;
  // const offset_min = 95;
  const difference_avg = getParity(hotel_price, channel_price);
  let severity = "ok";

  if (difference_avg !== 100) {
    if (difference_avg > 100 && difference_avg <= offset_max) {
      severity = "small";
    }
    if (difference_avg > offset_max) {
      severity = "critical";
    }
  }
  return severity;
}
export function getParity(web_price, ota_price) {
  // Cases:
  //            95%         100%         105%
  // ------------|------------|------------|---------
  //   agressive  conservative  misaligned  expensive
  //    primary      green        orange      red

  const difference = web_price - ota_price;
  const base = difference < 0 ? web_price : ota_price;

  let difference_avg = 100;
  if (difference !== 0) {
    difference_avg = 100 + (difference / base) * 100;
  }
  return difference_avg;
}
export function getParityCode(parity) {
  // Cases:
  //            95%         100%         105%
  // ------------|------------|------------|---------
  //   agressive  conservative  misaligned  expensive
  //    primary      green        orange      red

  const offset_max = 105;
  const offset_min = 95;
  let code = "conservative";

  if (parity == null) {
    code = "";
  } else if (parity < offset_min) {
    code = "agressive";
  } else if (parity > 100 && parity <= offset_max) {
    code = "misaligned";
  } else if (parity > offset_max) {
    code = "expensive";
  }
  return code;
}
export function getParityStyle(parity) {
  // Cases:
  //            95%         100%         105%
  // ------------|------------|------------|---------
  //   agressive  conservative  misaligned  expensive
  //    primary      green        orange      red

  const offset_max = 105;
  const offset_min = 95;
  let color = "#1B998B";
  let height = 80;

  if (parity == null) {
    return "";
  } else if (parity < offset_min) {
    color = "#2e21bd";
    height = 100;
  } else if (parity > 100 && parity <= offset_max) {
    color = "#FAA916";
    height = 50;
  } else if (parity > offset_max) {
    color = "#e64d3c";
    height = 25;
  }
  return "background-color: " + color + ";height:" + height + "%;";
}
export function showError(vm, error) {
  vm.$store.dispatch("setSnackbar", {
    title: vm.$t(error),
    color: "error"
  });
}

export function showSuccess(vm, message, title = undefined) {
  vm.$store.dispatch("setSnackbar", {
    title: title ? vm.$t(title) : title,
    text: vm.$t(message),
    color: "accent"
  });
}

export function getEventTypeIcon(type) {
  // Decide material design icon depending on event.type where possible values are:
  // "sports", "convention","local_event","international_event","enterainment"
  let icon = "";
  switch (type) {
    case "fair":
      icon = "mdi-calendar-star";
      break;
    case "sports":
      icon = "mdi-soccer";
      break;
    case "convention":
      icon = "mdi-briefcase-outline";
      break;
    case "local_holiday":
      icon = "mdi-map-marker-outline";
      break;
    case "national_holiday":
      icon = "mdi-flag-outline";
      break;
    case "international_holiday":
      icon = "mdi-earth";
      break;
    case "entertainment":
      icon = "mdi-music";
      break;
    default:
      icon = "mdi-calendar-outline";
      break;
  }
  return icon;
}

export function buildGetParams(data) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(value => params.append(key + "[]", value.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return "?" + params.toString();
}

// export function buildGetParams(data) {
//   let params = [];
//   for (let key in data) {
//     if (data.hasOwnProperty(key)) {
//       params.push(key + "=" + data[key]);
//     }
//   }
//   return "?" + params.join("&");
// }

export function getDuration(start_date, end_date) {
  return Vue.moment(start_date).isBefore(Vue.moment(end_date))
    ? Vue.moment(end_date).diff(Vue.moment(start_date), "days") + 1
    : Vue.moment(start_date).diff(Vue.moment(end_date), "days") + 1;
}

export function getFriendlyPeriod(start_date, end_date) {
  let sd = Vue.moment(start_date);
  let ed = Vue.moment(end_date);
  if (sd.isAfter(ed)) {
    sd = Vue.moment(end_date);
    ed = Vue.moment(start_date);
  }

  let m1 = cfl(sd.format("MMMM"));
  let m2 = cfl(ed.format("MMMM"));
  let y1 = sd.format("YYYY");
  let y2 = ed.format("YYYY");
  let days = "";
  ed.diff(sd, "days") + 1;
  if (m1 === m2 && y1 === y2) {
    if (sd.format("D") === ed.format("D"))
      days = sd.format("D") + " " + i18n.t("of") + " " + m1;
    else
      days =
        i18n.t("From") +
        " " +
        sd.format("D") +
        " " +
        i18n.t("to") +
        " " +
        ed.format("D") +
        " " +
        i18n.t("of") +
        " " +
        m1;
  } else if (y1 === y2) {
    days =
      i18n.t("From") +
      " " +
      sd.format("D") +
      " " +
      i18n.t("of") +
      " " +
      m1 +
      " " +
      i18n.t("to") +
      " " +
      ed.format("D") +
      " " +
      i18n.t("of") +
      " " +
      m2;
  } else {
    days =
      i18n.t("From") +
      " " +
      sd.format("D") +
      " " +
      i18n.t("of") +
      " " +
      m1 +
      ", " +
      y1 +
      " " +
      i18n.t("to") +
      " " +
      ed.format("D") +
      " " +
      i18n.t("of") +
      " " +
      m2 +
      ", " +
      y2;
  }
  return days;
}
export function getFriendlyDate(date, year = false) {
  if (!date) return "";
  return (
    Vue.moment(date).format("D") +
    " " +
    i18n.t("of") +
    " " +
    cfl(Vue.moment(date).format("MMMM")) +
    (year ? " " + Vue.moment(date).format("YYYY") : "")
  );
}
export function getFriendlyDatetime(date, year = false) {
  if (!date) return "";
  return (
    Vue.moment(date).format("D") +
    " " +
    i18n.t("of") +
    " " +
    cfl(Vue.moment(date).format("MMMM")) +
    (year ? " " + Vue.moment(date).format("YYYY") : "") +
    " " +
    i18n.t("at") +
    " " +
    Vue.moment(date).format("HH:mm")
  );
}
export function getFriendlyDay(date, year = false, day_mini = false) {
  if (!date) return "";
  let day = Vue.moment(date).format("dddd");
  if (day_mini) {
    day = Vue.moment(date).format("ddd");
  }
  return (
    day +
    ", " +
    Vue.moment(date).format("D") +
    " " +
    i18n.t("of") +
    " " +
    cfl(Vue.moment(date).format("MMM")) +
    (year ? " " + Vue.moment(date).format("YYYY") : "")
  );
}
export function getFriendlyDayStrategy(date, year = false) {
  if (!date) return "";
  return (
    "<span class='weekday'>" +
    Vue.moment(date).format("dd") +
    "</span>, " +
    Vue.moment(date).format("D") +
    " " +
    cfl(Vue.moment(date).format("MMM")) +
    (year ? " " + Vue.moment(date).format("YY") : "")
  ).replace(".", "");
}
export function getFriendlyMonthStrategy(date, year = false) {
  if (!date) return "";
  return (
    cfl(Vue.moment(date).format("MMM")) +
    (year ? " " + Vue.moment(date).format("YY") : "")
  ).replace(".", "");
}

export function channelManagerCellOperations() {
  return {
    absolute: value => value * 1,
    relative_currency: (inc, base) => base * 1 + inc * 1,
    relative_percentage: (p, base) =>
      Math.round(base * (1 + p / 100) * 100) / 100
  };
}

export function specialRatesCellOperations() {
  return {
    absolute: value => value * 1,
    relative_currency: (inc, base) => base * 1 + inc * 1,
    relative_percentage: (p, base) =>
      Math.round(base * (1 + p / 100) * 100) / 100
  };
}

export function offersCellOperations() {
  return {
    absolute: value => value * 1,
    relative_currency: (inc, base) => base * 1 + inc * 1,
    relative_percentage: (p, base) =>
      Math.round(base * (1 + p / 100) * 100) / 100
  };
}

export function generateArrayMonths(format = "MMM") {
  let months = [];
  for (let i = 0; i < 12; i++) {
    months.push({
      value: i,
      text: Vue.moment()
        .month(i)
        .format(format)
        .toUpperCase()
    });
  }
  return months;
}

export function justNumber(v) {
  return parseFloat(
    v
      .toString()
      .replace(",", ".")
      .replace(/[^-0-9.]/g, "")
  );
}

// Debe devolver siempre en formato numerico 000.00
export function cleanPunctuation(v, punctuation) {
  let v_string = v.toString();
  if (punctuation == "comma") {
    return v_string.replace(".", "").replace(",", ".");
  } else {
    return v_string.replace(",", "");
  }
}

export function downloadFile(data, filename) {
  const file = new Blob([data]);
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function getDimensionColor(items, code) {
  if (items) {
    let s = items.filter(s => s.code === String(code));
    if (s.length > 0) {
      return s[0].color;
    }
  }
  return "grey";
}

export function getDimensionName(items, code) {
  if (items) {
    let s = items.filter(s => s.code === String(code));
    if (s.length > 0) {
      return s[0].name;
    }
  }
  return code;
}

export function fN(v, format) {
  return numeral(v).format(format);
}

export function getTooltipTitle(title) {
  return (
    "<div style='text-align:left;font-family:\"Roboto Condensed\"'><span style='text-align:left; color: #bdbdbd; font-size:15px; font-weight:700;font-family:\"Roboto Condensed\";line-height:30px;'>" +
    cfl(title)
  );
}
export function getTooltipTableHeader(values, width = 170) {
  let header_style =
    "text-align:left; color: #666666; font-size:13px; font-weight:700;font-family:'Roboto Condensed';line-height:30px;";
  let t =
    "<table width='" +
    width +
    "px' cellspacing='0' cellpadding='0' style='padding:0'>";
  t += "<tr style='" + header_style + "'><td width='90px'></td>";
  let column_style = "";
  values.forEach((value, i) => {
    if (value) {
      column_style = !i
        ? "padding: 0px 10px;"
        : "padding: 0px 10px; text-align: right;";
      t += "<td style='" + column_style + "'>" + value + "</td>";
    }
  });
  t += "</tr>";
  return t;
}
export function getTooltipTableRow(row_style, values) {
  let column_style = "padding: 0px 10px;";
  let t = '<tr style="' + row_style + '">';
  values.forEach((value, i) => {
    if (value) {
      column_style = !i
        ? "padding: 0px 10px;"
        : "padding: 0px 10px; text-align: right;";
      t += '<td style="' + column_style + '">' + value + "</td>";
    }
  });
  t += "</tr>";
  return t;
}

export function getLabel(label, color, type = "segment") {
  return type === "segment"
    ? getSegmentLabel(label, color)
    : getPeriodLabel(label, color);
}
export function getSegmentLabel(segment, color) {
  return (
    "<span style='line-height: 16px; display:inline-block; margin: 3px 0px;padding: 0px 10px 2px 10px; border-radius: 15px;color: white; background-color: " +
    color +
    ";'>" +
    segment +
    "</span>"
  );
}
export function getPeriodLabel(period, color) {
  return (
    "<span style='display:inline-block; margin: 3px 0px;color: " +
    color +
    ";'>" +
    period +
    "</span>"
  );
}

export function getTooltip(e, state, getters, locale) {
  let id = e.id;
  let index = e.nodeindex;
  let t = "";
  let data;
  let data_original;
  let rows;
  let p = id.split("-");
  let month_index = p[p.length - 1];
  switch (id) {
    case "a-hotelsdot-pickup-segments-chart":
      data = state.rms.segments_pickup_month_graph;
      t += getTooltipTitle(
        "PICKUP " + data.labels[index] + " " + i18n.t("rn") + "</span>"
      );
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
      ]);
      rows = Object.keys(data.segments.rn.otb);
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(
          getDimensionName(state.segmentschannels.segments, segment),
          getDimensionColor(state.segmentschannels.segments, segment)
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.segments.rn.otb[segment][index], locale.number1_format),
            fN(data.segments.rn.stly[segment][index], locale.number1_format)
          ]
        );
      });
      break;
    case "a-hotelsdot-segments-chart":
      data = state.rms.segments_period_graph;
      // var label = getSegmentLabel(
      //   data.labels[index],
      //   state.hotelsdot.series_ui[data.labels[index]].color
      // );

      t += getTooltipTitle(
        i18n.t("rn") +
          " " +
          getSegmentLabel(
            data.labels[index],
            state.hotelsdot.series_ui[data.labels[index]].color
          ) +
          "</span>"
      );
      t += getTooltipTableHeader([null, "", ""], 250);
      rows = Object.keys(data.segments);
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(
          getDimensionName(state.segmentschannels.segments, segment),
          getDimensionColor(state.segmentschannels.segments, segment)
        );
        let values = rows.map(s => data.segments[s][index]);

        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            format_kpi_value(
              Math.round(data.segments[segment][index]),
              "number",
              locale
            ),
            "(" +
              format_kpi_value(
                getPercent(
                  data.segments[segment][index],
                  values.reduce((a, b) => a + b, 0)
                ),
                "percent",
                locale
              ) +
              ")"
          ]
        );
      });
      break;
    case "a-hotelsdot-pickup-roomrevenue-revpar-chart":
      data_original = state.rms.kpis_pickup_month_graph2;
      data = {
        labels: data_original.labels.slice().reverse(),
        kpis: {
          revpar: {
            otb: data_original.kpis.revpar.otb.slice().reverse(),
            stly: data_original.kpis.revpar.stly.slice().reverse()
          },
          room_revenue: {
            otb: data_original.kpis.room_revenue.otb.slice().reverse(),
            stly: data_original.kpis.room_revenue.stly.slice().reverse()
          }
        }
      };
      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        "Room Revenue",
        format_kpi_value(data.kpis.room_revenue.otb[index], "money", locale),
        format_kpi_value(data.kpis.room_revenue.stly[index], "money", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "REVPAR",
        format_kpi_value(data.kpis.revpar.otb[index], "money", locale),
        format_kpi_value(data.kpis.revpar.stly[index], "money", locale)
      ]);
      break;
    case "a-pickup-roomrevenue-revpar-chart-0":
    case "a-pickup-roomrevenue-revpar-chart-1":
    case "a-pickup-roomrevenue-revpar-chart-2":
      data = state.rms["kpis_pickup_month_" + month_index + "_graphs"];

      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        "Room Revenue",
        format_kpi_value(data.kpis.room_revenue.otb[index], "money", locale),
        format_kpi_value(data.kpis.room_revenue.stly[index], "money", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "REVPAR",
        format_kpi_value(data.kpis.revpar.otb[index], "money", locale),
        format_kpi_value(data.kpis.revpar.stly[index], "money", locale)
      ]);
      break;
    case "a-hotelsdot-pickup-occupation-chart":
      data_original = state.rms.kpis_pickup_month_graph1;
      data = {
        labels: data_original.labels.slice().reverse(),
        kpis: {
          occupancy: {
            otb: data_original.kpis.occupancy.otb.slice().reverse(),
            stly: data_original.kpis.occupancy.stly.slice().reverse()
          },
          adr: {
            otb: data_original.kpis.adr.otb.slice().reverse(),
            stly: data_original.kpis.adr.stly.slice().reverse()
          }
        }
      };
      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        cfl(i18n.t("occupancy")),
        format_kpi_value(data.kpis.occupancy.otb[index], "percent", locale),
        format_kpi_value(data.kpis.occupancy.stly[index], "percent", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "ADR",
        format_kpi_value(data.kpis.adr.otb[index], "money", locale),
        format_kpi_value(data.kpis.adr.stly[index], "money", locale)
      ]);
      break;
    case "a-pickup-occupation-adr-chart-0":
    case "a-pickup-occupation-adr-chart-1":
    case "a-pickup-occupation-adr-chart-2":
      data = state.rms["kpis_pickup_month_" + month_index + "_graphs"];
      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        cfl(i18n.t("occupancy")),
        format_kpi_value(data.kpis.occupancy.otb[index], "percent", locale),
        format_kpi_value(data.kpis.occupancy.stly[index], "percent", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "ADR",
        format_kpi_value(data.kpis.adr.otb[index], "money", locale),
        format_kpi_value(data.kpis.adr.stly[index], "money", locale)
      ]);
      break;
    case "a-occupation-adr-chart-monthly":
      data = state.rms.kpis_year_sales_months_graph;
      t += getTooltipTitle(data.labels[index] + "</span>");
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        cfl(i18n.t("occupancy")),
        format_kpi_value(data.kpis.occupancy.otb[index], "percent", locale),
        format_kpi_value(data.kpis.occupancy.stly[index], "percent", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "ADR",
        format_kpi_value(data.kpis.adr.otb[index], "money", locale),
        format_kpi_value(data.kpis.adr.stly[index], "money", locale)
      ]);
      break;
    case "a-occupation-adr-chart-quarterly":
      data = state.rms.kpis_year_sales_quarters_graph;
      t += getTooltipTitle(data.labels[index] + "</span>");
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        cfl(i18n.t("occupancy")),
        format_kpi_value(data.kpis.occupancy.otb[index], "percent", locale),
        format_kpi_value(data.kpis.occupancy.stly[index], "percent", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "ADR",
        format_kpi_value(data.kpis.adr.otb[index], "money", locale),
        format_kpi_value(data.kpis.adr.stly[index], "money", locale)
      ]);
      break;
    case "a-hotelsdot-occupation-chart":
      data = state.rms.kpis_monthly_graph;
      t += getTooltipTitle(
        Vue.moment(data.labels[index] + "-01").format("MMMM") + "</span>"
      );
      t += getTooltipTableHeader([null, cfl(i18n.t("occupancy"))]);
      ["otb", "stly", "bud", "ly"].forEach((period, i) => {
        t += getTooltipTableRow(current_row_style, [
          getPeriodLabel(period, state.hotelsdot.series_ui[period].color),
          format_kpi_value(
            data.kpis.occupancy[period][index],
            "percent",
            locale
          )
        ]);
      });
      break;
    case "a-hotelsdot-adr-chart":
      data = state.rms.kpis_monthly_graph;
      t += getTooltipTitle(
        Vue.moment(data.labels[index] + "-01").format("MMMM") + "</span>"
      );
      t += getTooltipTableHeader([null, i18n.t("adr")]);

      ["otb", "stly", "bud", "ly"].forEach((period, i) => {
        t += getTooltipTableRow(current_row_style, [
          getPeriodLabel(period, state.hotelsdot.series_ui[period].color),
          format_kpi_value(data.kpis.adr[period][index], "money", locale)
        ]);
      });
      break;
    case "a-hotelsdot-roomrevenue-chart":
      data = state.rms.kpis_monthly_graph;
      t += getTooltipTitle(
        Vue.moment(data.labels[index] + "-01").format("MMMM") + "</span>"
      );
      t += getTooltipTableHeader([null, i18n.t("room_revenue")]);

      ["otb", "stly", "bud", "ly"].forEach((period, i) => {
        t += getTooltipTableRow(current_row_style, [
          getPeriodLabel(period, state.hotelsdot.series_ui[period].color),
          format_kpi_value(
            data.kpis.room_revenue[period][index],
            "money",
            locale
          )
        ]);
      });
      break;
    case "a-hotelsdot-group-room-revenue-chart":
      data = getters.rms_filtered_hotels_kpis_period_graph;
      t += getTooltipTitle(i18n.t("room_revenue") + " " + data.labels[index]);
      t += getTooltipTableHeader([null, i18n.t("room_revenue")], 250);
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach((period, i) => {
        let label = getPeriodLabel(
          i18n.t(period),
          state.hotelsdot.series_ui[period].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.room_revenue[period][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-group-adr-chart":
      data = getters.rms_filtered_hotels_kpis_period_graph;
      t += getTooltipTitle(i18n.t("adr") + " " + data.labels[index]);
      t += getTooltipTableHeader([null, i18n.t("adr")], 250);
      rows = Object.keys(data.kpis.adr);
      rows.forEach((period, i) => {
        let label = getPeriodLabel(
          i18n.t(period),
          state.hotelsdot.series_ui[period].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.adr[period][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-group-occupancy-chart":
      data = getters.rms_filtered_hotels_kpis_period_graph;
      t += getTooltipTitle(i18n.t("occupancy") + " " + data.labels[index]);
      t += getTooltipTableHeader([null, i18n.t("occupancy")], 250);
      rows = Object.keys(data.kpis.occupancy);
      rows.forEach((period, i) => {
        let label = getPeriodLabel(
          i18n.t(period),
          state.hotelsdot.series_ui[period].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [label, fN(data.kpis.adr[period][index], locale.percent_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-group-rn-chart":
      data = getters.rms_filtered_hotels_kpis_period_graph;
      t += getTooltipTitle(i18n.t("rn") + " " + data.labels[index]);
      t += getTooltipTableHeader([null, i18n.t("rn")], 250);
      rows = Object.keys(data.kpis.rn);
      rows.forEach((period, i) => {
        let label = getPeriodLabel(
          i18n.t(period),
          state.hotelsdot.series_ui[period].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [label, fN(data.kpis.rn[period][index], locale.number1_format) + "rn"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-roomrevenue-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("room_revenue") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("room_revenue")], 250);
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.room_revenue[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-rms-dashboard-year-roomrevenue-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      // t += getTooltipTitle(
      //   i18n.t("room_revenue") +
      //     " " +
      //     Vue.moment(data.labels[index])
      //       .format("MMMM")
      //       .substr(0, 1)
      //       .toUpperCase() +
      //     Vue.moment(data.labels[index])
      //       .format("MMMM")
      //       .substr(1)
      // );
      t += getTooltipTableHeader(
        [
          null,
          i18n.t("room_revenue") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1)
        ],
        250
      );
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.room_revenue[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-roomrevenue-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(
        i18n.t("room_revenue") + " " + i18n.t("Accumulative")
      );
      t += getTooltipTableHeader([null, i18n.t("room_revenue")], 150);
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.room_revenue[i], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-roomrevenue-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("room_revenue") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1)
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.room_revenue[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-adr-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("adr") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("adr")], 250);
      rows = Object.keys(data.kpis.adr);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.adr[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-adr-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(i18n.t("adr") + " " + i18n.t("Accumulative"));
      t += getTooltipTableHeader([null, i18n.t("adr")], 150);
      rows = Object.keys(data.kpis.adr);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.adr[i], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-adr-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("adr") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.adr);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1)
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.adr[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-rn-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("rn") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("rn")], 250);
      rows = Object.keys(data.kpis.rn);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [label, fN(data.kpis.rn[kpi][index], locale.number1_format) + "rn"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-rn-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(i18n.t("rn") + " " + i18n.t("Accumulative"));
      t += getTooltipTableHeader([null, i18n.t("rn")], 150);
      rows = Object.keys(data.kpis.rn);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.rn[i], locale.number1_format) + "rn"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-rn-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("rn") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.rn);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1)
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.rn[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-occupancy-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("occupancy") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("occupancy")], 250);
      rows = Object.keys(data.kpis.occupancy);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.occupancy[kpi][index], locale.percent1_format) + "%"
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-occupancy-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(i18n.t("occupancy") + " " + i18n.t("Accumulative"));
      t += getTooltipTableHeader([null, i18n.t("occupancy")], 150);
      rows = Object.keys(data.kpis.occupancy);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.occupancy[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-occupancy-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("occupancy") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.occupancy);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1)
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.occupancy[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-revpar-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("revpar") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("revpar")], 250);
      rows = Object.keys(data.kpis.revpar);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.revpar[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-revpar-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(i18n.t("revpar") + " " + i18n.t("Accumulative"));
      t += getTooltipTableHeader([null, i18n.t("revpar")], 150);
      rows = Object.keys(data.kpis.revpar);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.revpar[i], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-revpar-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("revpar") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.revpar);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1),
          "#0077b6"
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.revpar[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-intermediation-monthly-chart":
      data = state.rms.year_sales_monthly_graph;
      t += getTooltipTitle(
        i18n.t("intermediation") +
          " " +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
          Vue.moment(data.labels[index])
            .format("MMMM")
            .substr(1)
      );
      t += getTooltipTableHeader([null, i18n.t("intermediation")], 250);
      rows = Object.keys(data.kpis.intermediation);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.intermediation[kpi][index], locale.percent1_format) +
              "%"
          ]
        );
      });
      break;
    case "a-hotelsdot-year-sales-intermediation-period-chart":
      data = state.rms.year_sales_period_graph;
      t += getTooltipTitle(
        i18n.t("intermediation") + " " + i18n.t("Accumulative")
      );
      t += getTooltipTableHeader([null, i18n.t("intermediation")], 150);
      rows = Object.keys(data.kpis.intermediation);
      rows.forEach(i => {
        let label = getPeriodLabel(
          data.labels[i],
          state.hotelsdot.series_ui[data.labels[i]].color
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.intermediation[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-year-sales-intermediation-grown-chart":
      data = state.rms.year_sales_monthly_grown_graph;
      t += getTooltipTitle(i18n.t("intermediation") + " " + i18n.t("growth"));
      t += getTooltipTableHeader([null, i18n.t("growth")], 250);
      rows = Object.keys(data.kpis.intermediation);
      rows.forEach(i => {
        let label = getPeriodLabel(
          Vue.moment(data.labels[i])
            .format("MMMM")
            .substr(0, 1)
            .toUpperCase() +
            Vue.moment(data.labels[i])
              .format("MMMM")
              .substr(1)
        );
        t += getTooltipTableRow(
          i == e.nodeindex ? current_row_style : row_style,
          [label, fN(data.kpis.intermediation[i], locale.percent1_format) + "%"]
        );
      });
      break;
    case "a-hotelsdot-segmentation-report-rn-chart":
      data = state.rms.segmentation_report_graph;
      if (data.labels[index].length === 10) {
        t += getTooltipTitle(
          i18n.t("rn") +
            " - " +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(1) +
            ", " +
            Vue.moment(data.labels[index]).format("DD") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1, 2) +
            "</span>"
        );
      } else {
        t += getTooltipTitle(
          i18n.t("rn") +
            ", " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1) +
            "</span>"
        );
      }
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
      ]);
      rows = Object.keys(data.otb)
        .sort((a, b) => data.otb[b][index] - data.otb[a][index])
        .filter(
          dimension =>
            (data.otb[dimension] && data.otb[dimension][index] > 0) ||
            (data.stly[dimension] && data.stly[dimension][index] > 0)
        );
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(segment, "grey");
        if (state.rms.segmentation_report_dimension === "segment") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segments, segment),
            getDimensionColor(state.segmentschannels.segments, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "channel") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segment_channels, segment),
            getDimensionColor(state.segmentschannels.segment_channels, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "roomtype") {
          label = getSegmentLabel(
            getDimensionName(getters.pmsRoomTypes, segment),
            getDimensionColor(getters.pmsRoomTypes, segment)
          );
        }
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.otb[segment][index], locale.number_format) + "rn",
            data.stly[segment]
              ? fN(data.stly[segment][index], locale.number_format) + "rn"
              : "-"
          ]
        );
      });
      break;
    case "a-hotelsdot-segmentation-report-roomrevenue-chart":
      data = state.rms.segmentation_report_graph;
      if (data.labels[index].length === 10) {
        t += getTooltipTitle(
          i18n.t("room_revenue") +
            " - " +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(1) +
            ", " +
            Vue.moment(data.labels[index]).format("DD") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1, 2) +
            "</span>"
        );
      } else {
        t += getTooltipTitle(
          i18n.t("room_revenue") +
            ", " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1) +
            "</span>"
        );
      }
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
      ]);
      rows = Object.keys(data.otb)
        .sort((a, b) => data.otb[b][index] - data.otb[a][index])
        .filter(
          dimension =>
            (data.otb[dimension] && data.otb[dimension][index] > 0) ||
            (data.stly[dimension] && data.stly[dimension][index] > 0)
        );
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(segment, "grey");
        if (state.rms.segmentation_report_dimension === "segment") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segments, segment),
            getDimensionColor(state.segmentschannels.segments, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "channel") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segment_channels, segment),
            getDimensionColor(state.segmentschannels.segment_channels, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "roomtype") {
          label = getSegmentLabel(
            getDimensionName(getters.pmsRoomTypes, segment),
            getDimensionColor(getters.pmsRoomTypes, segment)
          );
        }
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.otb[segment][index], locale.number1_format) +
              locale.money_post_symbol,
            data.stly[segment]
              ? fN(data.stly[segment][index], locale.number1_format) +
                locale.money_post_symbol
              : "-"
          ]
        );
      });
      break;
    case "a-hotelsdot-segmentation-report-adr-chart":
      data = state.rms.segmentation_report_graph;
      if (data.labels[index].length === 10) {
        t += getTooltipTitle(
          i18n.t("adr") +
            " - " +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(1) +
            ", " +
            Vue.moment(data.labels[index]).format("DD") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1, 2) +
            "</span>"
        );
      } else {
        t += getTooltipTitle(
          i18n.t("adr") +
            ", " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1) +
            "</span>"
        );
      }
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
      ]);
      rows = Object.keys(data.otb)
        .sort((a, b) => data.otb[b][index] - data.otb[a][index])
        .filter(
          dimension =>
            (data.otb[dimension] && data.otb[dimension][index] > 0) ||
            (data.stly[dimension] && data.stly[dimension][index] > 0)
        );
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(segment, "grey");
        if (state.rms.segmentation_report_dimension === "segment") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segments, segment),
            getDimensionColor(state.segmentschannels.segments, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "channel") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segment_channels, segment),
            getDimensionColor(state.segmentschannels.segment_channels, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "roomtype") {
          label = getSegmentLabel(
            getDimensionName(getters.pmsRoomTypes, segment),
            getDimensionColor(getters.pmsRoomTypes, segment)
          );
        }
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.otb[segment][index], locale.number1_format) +
              locale.money_post_symbol,
            data.stly[segment]
              ? fN(data.stly[segment][index], locale.number1_format) +
                locale.money_post_symbol
              : "-"
          ]
        );
      });
      break;
    case "a-hotelsdot-segmentation-report-occupancy-chart":
      data = state.rms.segmentation_report_graph;
      if (data.labels[index].length === 10) {
        t += getTooltipTitle(
          i18n.t("occupancy") +
            " - " +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("dddd")
              .substr(1) +
            ", " +
            Vue.moment(data.labels[index]).format("DD") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1, 2) +
            "</span>"
        );
      } else {
        t += getTooltipTitle(
          i18n.t("adr") +
            ", " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1) +
            "</span>"
        );
      }
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
      ]);
      rows = Object.keys(data.otb).sort(
        (a, b) => data.otb[b][0] - data.otb[a][0]
      );
      rows.forEach((segment, i) => {
        let label = getSegmentLabel(segment, "grey");
        if (state.rms.segmentation_report_dimension === "segment") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segments, segment),
            getDimensionColor(state.segmentschannels.segments, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "channel") {
          label = getSegmentLabel(
            getDimensionName(state.segmentschannels.segment_channels, segment),
            getDimensionColor(state.segmentschannels.segment_channels, segment)
          );
        } else if (state.rms.segmentation_report_dimension === "roomtype") {
          label = getSegmentLabel(
            getDimensionName(getters.pmsRoomTypes, segment),
            getDimensionColor(getters.pmsRoomTypes, segment)
          );
        }
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.otb[segment][index], locale.number1_format) + "%",
            data.stly[segment]
              ? fN(data.stly[segment][index], locale.number1_format) + "%"
              : "-"
          ]
        );
      });
      break;
    case "a-hotelsdot-pricing-report-graph":
      data = state.channelmanager.channelManagerGraphData;
      t += getTooltipTitle(
        Vue.moment(data[0].values[index][0])
          .format("D MMMM")
          .toUpperCase() + "</span>"
      );
      t += getTooltipTableHeader([null, getPeriodLabel("PVP")]);
      rows = data;
      rows.forEach((roomtype, i) => {
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [roomtype.name, fN(roomtype.values[index][1], locale.money_format)]
        );
      });
      break;
    case "a-rms-revenue-channels-chart":
      data = getters.rms_revenue_channels_graph_data;
      t += getTooltipTitle(
        getDimensionName(
          state.segmentschannels.segment_channels,
          data.labels[index]
        )
      );
      t += getTooltipTableHeader([
        null,
        getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
        getPeriodLabel(
          state.rms.revenue_vs.toUpperCase(),
          state.hotelsdot.series_ui[state.rms.revenue_vs].color
        )
      ]);
      t += getTooltipTableRow(row_style, [
        i18n.t("room_revenue"),
        fN(data.series.room_revenue["otb"][index], locale.number1_format) +
          locale.money_post_symbol,
        fN(
          data.series.room_revenue[state.rms.revenue_vs][index],
          locale.number1_format
        ) + locale.money_post_symbol
      ]);
      t += getTooltipTableRow(row_style, [
        i18n.t("rn"),
        fN(data.series.rn_adr["otb"][index][2], locale.number1_format) + "rn",
        fN(
          data.series.rn_adr[state.rms.revenue_vs][index][2],
          locale.number1_format
        ) + "rn"
      ]);
      t += getTooltipTableRow(row_style, [
        i18n.t("adr"),
        fN(data.series.rn_adr["otb"][index][1], locale.number1_format) +
          locale.money_post_symbol,
        fN(
          data.series.rn_adr[state.rms.revenue_vs][index][1],
          locale.number1_format
        ) + locale.money_post_symbol
      ]);
      break;
    case "a-rms-revenue-servicetype-chart":
      data = getters.rms_revenue_servicetype_data;
      t += getTooltipTitle(i18n.t("production_of_" + data.labels[index]));
      t += getTooltipTableHeader([null, null]);
      rows = Object.keys(data.series);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.series[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-rms-revenue-days-to-reservation-chart":
      data = getters.rms_revenue_days_to_reservation_graph;
      t += getTooltipTitle(i18n.t("advance_bookings_" + data.labels[index]));
      t += getTooltipTableHeader([null, i18n.t("bookings")]);
      rows = Object.keys(data.series);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [label, fN(data.series[kpi][index], locale.number1_format) + "%"]
        );
      });
      break;
    case "a-rms-revenue-days-to-cancellation-chart":
      data = getters.rms_revenue_days_to_cancellation_graph;
      t += getTooltipTitle(
        i18n.t("cancellation_bookings_" + data.labels[index])
      );
      t += getTooltipTableHeader([null, i18n.t("bookings")]);
      rows = Object.keys(data.series);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [label, fN(data.series[kpi][index], locale.number1_format) + "%"]
        );
      });
      break;
    default:
      // DO nothing
      break;
  }

  //Delete last part that can be hotel_id
  let id_simple = id
    .split("-")
    .slice(0, -1)
    .join("-");
  let hotel_id = id
    .split("-")
    .slice(-1)
    .join("-");
  switch (id_simple) {
    case "a-dashboard-year-sales-roomrevenue-monthly-chart":
      data = state.rms.group_hotels[hotel_id].year_sales_monthly_graph;
      t += getTooltipTableHeader(
        [
          null,
          i18n.t("room_revenue") +
            " " +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(0, 1)
              .toUpperCase() +
            Vue.moment(data.labels[index])
              .format("MMMM")
              .substr(1)
        ],
        250
      );
      rows = Object.keys(data.kpis.room_revenue);
      rows.forEach((kpi, i) => {
        let label = getPeriodLabel(
          i18n.t(kpi),
          state.hotelsdot.series_ui[kpi].color
        );
        t += getTooltipTableRow(
          i === e.plotindex % rows.length ? current_row_style : row_style,
          [
            label,
            fN(data.kpis.room_revenue[kpi][index], locale.number1_format) +
              locale.money_post_symbol
          ]
        );
      });
      break;
    case "a-pickup-roomrevenue-revpar-chart-current-month":
      data = state.rms.group_hotels[hotel_id].kpis_pickup_month_graphs;
      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        "Room Revenue",
        format_kpi_value(data.kpis.room_revenue.otb[index], "money", locale),
        format_kpi_value(data.kpis.room_revenue.stly[index], "money", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "REVPAR",
        format_kpi_value(data.kpis.revpar.otb[index], "money", locale),
        format_kpi_value(data.kpis.revpar.stly[index], "money", locale)
      ]);
      break;
    case "a-pickup-roomrevenue-revpar-chart-next-month":
      data = state.rms.group_hotels[hotel_id].kpis_pickup_next_month_graphs;
      t += getTooltipTitle(
        i18n.t("pickup") + " " + data.labels[index] + "</span>"
      );
      t += getTooltipTableHeader(
        [
          null,
          getPeriodLabel("OTB", state.hotelsdot.series_ui["otb"].color),
          getPeriodLabel("STLY", state.hotelsdot.series_ui["stly"].color)
        ],
        250
      );
      t += getTooltipTableRow(current_row_style, [
        "Room Revenue",
        format_kpi_value(data.kpis.room_revenue.otb[index], "money", locale),
        format_kpi_value(data.kpis.room_revenue.stly[index], "money", locale)
      ]);
      t += getTooltipTableRow(current_row_style, [
        "REVPAR",
        format_kpi_value(data.kpis.revpar.otb[index], "money", locale),
        format_kpi_value(data.kpis.revpar.stly[index], "money", locale)
      ]);
      break;
  }
  return {
    text: t + "</table></div>"
  };
}

export function daysOfYear(year) {
  const startOfYear = Vue.moment(year, "YYYY").startOf("year");
  const endOfYear = Vue.moment(year, "YYYY").endOf("year");
  return endOfYear.diff(startOfYear, "days") + 1; // Adding 1 to include the last day
}

export function getYearlyCalendar(year, getters) {
  let first_day = year + "-01-01";
  const listaDias = {};

  for (let dia = 0; dia < daysOfYear(year); dia++) {
    const fecha = Vue.moment(first_day, "YYYY-MM-DD").add(dia, "days");
    const mes = fecha.format("MMMM");
    let semana = fecha.week().toString();

    // Ajustar la semana si el primer día del año está en la última semana del año anterior
    if (semana > 5 && fecha.format("MM") === "01") {
      semana = 0;
    }
    if (semana === "1" && fecha.format("MM") === "12") {
      semana = 53;
    }

    if (!listaDias[mes]) {
      // Si el mes no existe en la lista, crearlo
      listaDias[mes] = {};
    }

    if (!listaDias[mes][semana]) {
      // Si la semana no existe en la lista para el mes actual, crearla
      listaDias[mes][semana] = [null, null, null, null, null, null, null];
    }

    const diaSemana = fecha.weekday();
    listaDias[mes][semana][diaSemana] = {
      // active: getters.isOpen(fecha),
      // can_open: getters.canOpen(fecha),
      // can_close: getters.canClose(fecha),
      date: fecha.format("YYYY-MM-DD"),
      year: fecha.format("YYYY"),
      month: fecha.format("MM"),
      week: semana
    };
    if (getters) {
      listaDias[mes][semana][diaSemana]["active"] = getters.isOpen(fecha);
      listaDias[mes][semana][diaSemana]["can_open"] = getters.canOpen(fecha);
      listaDias[mes][semana][diaSemana]["can_close"] = getters.canClose(fecha);
    }
  }
  return listaDias;
}

export function getDates(init_date, max_dates) {
  let dates = [];
  let init_date_moment = Vue.moment(init_date).add(
    Math.ceil((-1 * max_dates) / 2),
    "days"
  );
  for (let dia = 0; dia < max_dates; dia++) {
    const fecha = Vue.moment(init_date_moment).add(dia, "days");
    dates.push(fecha.format("YYYY-MM-DD"));
  }
  return dates;
}
