import Vue from "vue";
import i18n from "@/plugins/i18n";
import RmsService from "@/services/RmsService.js";

export const state = {
  // status
  kpis_monthly_graph_status: 0,
  segments_period_graph_status: 0,

  kpis_pickup_month_graph1_status: 0,
  kpis_pickup_month_graph2_status: 0,
  kpis_month_graph_status: 0,

  dashboard_kpis_pickup_month_graph_status: 0,

  annual_kpis_status: 0,
  ytd_annual_kpis_status: 0,
  month_kpis_status: 0,
  ytd_month_kpis_status: 0,
  all_kpis_daily_status: 0,
  all_kpis_daily_compset_status: 0,
  weather_daily_status: 0,
  all_kpis_daily_pickup_status: 0,
  pvp_mlos_changes_daily_status: 0,
  all_kpis_daily_dimension_status: 0,
  all_kpis_period_dimension_status: 0,
  kpis_year_sales_months_status: 0,
  kpis_year_sales_quarters_status: 0,
  kpis_year_sales_year_status: 0,
  hotels_kpis_period_status: 0,
  hotels_kpis_period_graph_status: 0,
  year_sales_status: 0,
  year_sales_monthly_graph_status: 0,
  year_sales_period_graph_status: 0,
  year_sales_monthly_grown_graph_status: 0,
  year_sales_table_status: 0,
  year_sales_period_table_status: 0,
  roomtype_availability_status: 0,
  booking_pace_status: 0,
  booking_pace_weekly_status: 0,
  segmentation_report_graph_status: 0,
  segmentation_report_table_status: 0,
  dashboard_year_sales_monthly_graph_status: 0,
  dashboard_annual_kpis_status: 0,
  dashboard_booking_pace_month_graphs_status: 0,
  dashboard_month_kpis_status: 0,
  revenue_summary_table_status: 0,
  revenue_channels_table_status: 0,
  revenue_servicetype_data_status: 0,
  revenue_range_kpis_status: 0,
  revenue_days_to_reservation_graph_status: 0,
  revenue_days_to_cancellation_graph_status: 0,

  segments_pickup_month_graph_status: 0,
  strategy_range_kpis_status: null,
  strategy_range_kpis_table_status: null,
  strategy_range_kpis_pickups_table_status: null,

  // dataset
  kpis_monthly_graph: null,
  segments_period_graph: null,

  kpis_month_graph: null,
  kpis_pickup_month_graph1: null,
  kpis_pickup_month_graph2: null,
  segments_pickup_month_graph: null,
  annual_kpis: null,
  ytd_annual_kpis: null,
  month_kpis: null,
  ytd_month_kpis: null,
  all_kpis_daily: null,
  all_kpis_daily_compset: null,
  all_kpis_compset_by_date: {},
  weather_daily: null,
  all_kpis_daily_pickup: null,
  pvp_mlos_changes_daily: null,
  all_kpis_daily_dimension: null,
  all_kpis_period_dimension: null,
  kpis_year_sales_months: null,
  kpis_year_sales_months_graph: null,
  kpis_year_sales_q1: null,
  kpis_year_sales_q2: null,
  kpis_year_sales_q3: null,
  kpis_year_sales_q4: null,
  kpis_year_sales_quarters_graph: null,
  kpis_year_sales_year: null,
  hotels_kpis_period: null,
  hotels_kpis_period_graph: null,
  year_sales: "",
  year_sales_monthly_graph: "",
  year_sales_period_graph: "",
  year_sales_monthly_grown_graph: "",
  year_sales_table: "",
  year_sales_period_table: "",
  strategy_range_kpis: null,
  strategy_range_kpis_table: null,
  strategy_range_kpis_pickups_table: null,
  booking_pace: null,
  booking_pace_weekly: null,
  segmentation_report_graph: null,
  segmentation_report_table: null,
  dashboard_annual_kpis: null,
  kpis_pickup_month_0_graphs: {},
  booking_pace_0: {},
  kpis_pickup_month_1_graphs: {},
  booking_pace_1: {},
  kpis_pickup_month_2_graphs: {},
  booking_pace_2: {},
  dashboard_month_kpis: {},
  revenue_summary_table: null,
  revenue_channels_table: null,
  revenue_servicetype_data: null,
  revenue_range_kpis: null,
  revenue_days_to_reservation_graph: null,
  revenue_days_to_cancellation_graph: null,
  group_hotels: {},
  group_hotels_status: 0,

  // ui selectors
  current_pickup_year: null,
  current_pickup_month: null,
  strategy_from: "",
  strategy_to: "",
  strategy_spot_date: "",
  availability_from: "",
  availability_to: "",
  revenue_from: "",
  revenue_to: "",
  revenue_vs: "",
  current_hotels_kpis_period: null,
  show_bud: true,
  show_stly: true,
  show_ly: false,
  show_pickup_1: true,
  show_pickup_2: false,
  show_pickup_3: true,
  show_pickup_4: false,
  show_pickup_5: true,
  show_pickup_6: true,
  hotels_kpi_hover: "",
  year_sales_commission: "mixed",
  year_sales_ly_year: null,
  year_sales_kpi_graph: "",
  year_sales_kpi_hover: "",
  year_sales_year: "",
  day_general_kpi_hover: "",
  day_general_pickup_hover: "",
  day_general_roomtype_hover: "",
  strategy_date_hover: "",
  strategy_kpi_hover: "",
  strategy_kpi_table_hover: "",
  strategy_kpi_pickups_table_hover: "",
  availability_roomtype_kpi_hover: "",
  segmentation_report_commission: "",
  segmentation_report_breakdown: "",
  segmentation_report_from: "",
  segmentation_report_to: "",
  segmentation_report_dimension: "",
  segmentation_report_kpi_graph: "",
  segmentation_report_hover: "",
  strategy_vs_spot: "stly",
  hotel_list_items_slide: 0,
  hotels_search_query: "",
  dashboard_group_vs_data: "bud",
  dashboard_pickup_months_page: 0,

  mktd_min: 45,
  mktd_max: 60,
  occupancy_min: 30,
  occupancy_max: 70,
  roomtype_availability: {},
  dashboard_first_day: "",
  dashboard_last_day: "",
  dashboard_year_full_view: false,
  dashboard_year: 0
};

export const mutations = {
  GET_DATASET(state, { dataset, data }) {
    state[dataset] = data;
  },
  SET_DATASET_STATUS(state, { dataset, status }) {
    state[dataset + "_status"] = status;
  },
  SET_CURRENT_PICKUP_PERIOD(state, item) {
    state.current_pickup_month = item.month;
    state.current_pickup_year = item.year;
  },
  SET_STRATEGY_FROM(state, v) {
    state.strategy_from = v;
  },
  SET_STRATEGY_TO(state, v) {
    state.strategy_to = v;
  },
  SET_STRATEGY_SPOT_DATE(state, v) {
    state.strategy_spot_date = v;
  },
  SET_AVAILABILITY_FROM(state, v) {
    state.availability_from = v;
  },
  SET_AVAILABILITY_TO(state, v) {
    state.availability_to = v;
  },
  SET_REVENUE_FROM(state, v) {
    state.revenue_from = v;
  },
  SET_REVENUE_TO(state, v) {
    state.revenue_to = v;
  },
  SET_REVENUE_VS(state, v) {
    state.revenue_vs = v;
  },
  SET_CURRENT_HOTELS_KPIS_PERIOD(state, period) {
    state.current_hotels_kpis_period = period;
  },
  SET_RMS_HOTELS_KPI_HOVER(state, payload) {
    state.hotels_kpi_hover = payload;
  },
  SET_RMS_STRATEGY_DATE_HOVER(state, payload) {
    state.strategy_date_hover = payload;
  },
  SET_RMS_STRATEGY_KPI_HOVER(state, payload) {
    state.strategy_kpi_hover = payload;
  },
  SET_RMS_STRATEGY_KPI_TABLE_HOVER(state, payload) {
    state.strategy_kpi_table_hover = payload;
  },
  SET_RMS_STRATEGY_KPI_PICKUPS_TABLE_HOVER(state, payload) {
    state.strategy_kpi_pickups_table_hover = payload;
  },
  SET_AVAILABILITY_ROOMTYPE_KPI_HOVER(state, payload) {
    state.availability_roomtype_kpi_hover = payload;
  },
  SET_SHOW(state, payload) {
    state["show_" + payload.vs] = payload.value;
  },
  SET_YEAR_SALES_COMMISSION(state, v) {
    state.year_sales_commission = v;
  },
  SET_YEAR_SALES_LY_YEAR(state, v) {
    state.year_sales_ly_year = v;
  },
  SET_YEAR_SALES_KPI_GRAPH(state, v) {
    state.year_sales_kpi_graph = v;
  },
  SET_YEAR_SALES_KPI_HOVER(state, v) {
    state.year_sales_kpi_hover = v;
  },
  SET_YEAR_SALES_YEAR(state, v) {
    state.year_sales_year = v;
  },
  SET_DAY_GENERAL_KPI_HOVER(state, v) {
    state.day_general_kpi_hover = v;
  },
  SET_DAY_GENERAL_PICKUP_HOVER(state, v) {
    state.day_general_pickup_hover = v;
  },
  SET_DAY_GENERAL_ROOMTYPE_HOVER(state, v) {
    state.day_general_roomtype_hover = v;
  },
  SET_SEGMENTATION_REPORT_COMMISSION(state, v) {
    state.segmentation_report_commission = v;
  },
  SET_SEGMENTATION_REPORT_BREAKDOWN(state, v) {
    state.segmentation_report_breakdown = v;
  },
  SET_SEGMENTATION_REPORT_FROM(state, v) {
    state.segmentation_report_from = v;
  },
  SET_SEGMENTATION_REPORT_TO(state, v) {
    state.segmentation_report_to = v;
  },
  SET_SEGMENTATION_REPORT_DIMENSION(state, v) {
    state.segmentation_report_dimension = v;
  },
  SET_SEGMENTATION_REPORT_KPI_GRAPH(state, v) {
    state.segmentation_report_kpi_graph = v;
  },
  SET_SEGMENTATION_REPORT_HOVER(state, v) {
    state.segmentation_report_hover = v;
  },
  SET_STRATEGY_VS_SPOT(state, v) {
    state.strategy_vs_spot = v;
  },
  SET_DASHBOARD_FIRST_DAY(state, v) {
    state.dashboard_first_day = v;
  },
  SET_DASHBOARD_LAST_DAY(state, v) {
    state.dashboard_last_day = v;
  },
  SET_DASHBOARD_YEAR_FULL_VIEW(state, v) {
    state.dashboard_year_full_view = v;
  },
  SET_DASHBOARD_YEAR(state, v) {
    state.dashboard_year = v;
  },
  SET_HOTEl_DATA(state, p) {
    Vue.set(state.group_hotels, p.hotel.id, p.item);
  },
  SET_DASHBOARD_GROUP_STATUS(state, v) {
    state.group_hotels_status = v;
  },
  SET_HOTEL_LIST_ITEMS_SLIDE(state, p) {
    state.hotel_list_items_slide = p;
  },
  SET_RMS_HOTELS_SEARCH_QUERY(state, payload) {
    state.hotels_search_query = payload;
  },
  SET_DASHBOARD_GROUP_VS_DATA(state, v) {
    state.dashboard_group_vs_data = v;
  },
  SET_DASHBOARD_PICKUP_MONTHS_PAGE(state, v) {
    state.dashboard_pickup_months_page = v;
  },
  SET_ALL_KPIS_COMPSET_BY_DATE(state, payload) {
    Vue.set(state.all_kpis_compset_by_date, payload.date, payload.data);
  }
};

export const actions = {
  // DONE
  fetchRmsKpisMonthlyGraph({ commit, state, rootGetters }, p) {
    const dataset = "kpis_monthly_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let from_date = Vue.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    let to_date = Vue.moment()
      .startOf("month")
      .add(3, "month")
      .endOf("month")
      .format("YYYY-MM-DD");

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["adr", "room_revenue", "occupancy"],
      spots: ["otb", "ly", "bud", "stly"],
      from_date: from_date,
      to_date: to_date
    };
    RmsService.getKpisMonthlyGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsSegmentsPeriodGraph({ commit, rootGetters }, p) {
    const dataset = "segments_period_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let from_date = Vue.moment()
      .startOf("year")
      .format("YYYY-MM-DD");
    let to_date = Vue.moment()
      .endOf("year")
      .format("YYYY-MM-DD");

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["adr", "room_revenue", "occupancy"],
      from_date: from_date,
      to_date: to_date
    };
    RmsService.getSegmentsPeriodGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsKpisPickupMonthGraph2({ commit, rootGetters }, p) {
    const dataset = "kpis_pickup_month_graph2";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_month ||
      !state.current_pickup_year
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "revpar"],
      month: state.current_pickup_month,
      year: state.current_pickup_year
    };
    RmsService.getKpisPickupMonthGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsKpisPickupMonthGraph1({ commit, rootGetters }, p) {
    const dataset = "kpis_pickup_month_graph1";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_month ||
      !state.current_pickup_year
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["occupancy", "adr"],
      month: state.current_pickup_month,
      year: state.current_pickup_year
    };
    RmsService.getKpisPickupMonthGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsSegmentsPickupMonthGraph({ commit, rootGetters }, p) {
    const dataset = "segments_pickup_month_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_month ||
      !state.current_pickup_year
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: "rn",
      month: state.current_pickup_month,
      year: state.current_pickup_year
    };
    RmsService.getSegmentsPickupMonthGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsBookingPace({ commit, rootGetters }, p) {
    const dataset = "booking_pace";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_month ||
      !state.current_pickup_year
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      month: state.current_pickup_month,
      year: state.current_pickup_year
    };
    RmsService.getBookingPace(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsBookingPaceWeekly({ commit, rootGetters }, p) {
    const dataset = "booking_pace_weekly";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getBookingPaceWeeks(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setCurrentPickupPeriod({ commit }, item) {
    commit("SET_CURRENT_PICKUP_PERIOD", item);
  },
  fetchRmsAnnualKpis({ commit, rootGetters }, p) {
    const dataset = "annual_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD")
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYtdAnnualKpis({ commit, rootGetters }, p) {
    const dataset = "ytd_annual_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment().format("YYYY-MM-DD")
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsDashboardAnnualKpis({ commit, rootGetters }, p) {
    const dataset = "dashboard_annual_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.dashboard_year
    ) {
      return null;
    }

    let from_date = Vue.moment()
      .year(state.dashboard_year)
      .startOf("year")
      .format("YYYY-MM-DD");
    let to_date = state.dashboard_year_full_view
      ? Vue.moment()
          .year(state.dashboard_year)
          .endOf("year")
          .format("YYYY-MM-DD")
      : Vue.moment()
          .year(state.dashboard_year)
          .format("YYYY-MM-DD");

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "occupancy", "revpar", "rn", "free_rooms", "adr"],
      from_date: from_date,
      to_date: to_date
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsDashboardMonthKpis({ commit, rootGetters }, p) {
    const dataset = "dashboard_month_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let init_month = state.dashboard_pickup_months_page * 3;
    let end_month = (state.dashboard_pickup_months_page + 1) * 3 - 1;
    let from_date = Vue.moment()
      .startOf("month")
      .add(init_month, "months")
      .format("YYYY-MM-DD");
    let to_date = Vue.moment()
      .add(end_month, "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["occupancy", "adr", "room_revenue", "revpar", "rn"],
      from_date: from_date,
      to_date: to_date,
      breakdown: "monthly",
      spots: ["otb", "stly"]
    };
    RmsService.getMonthlyGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setDashboardPickupMonthsPage({ commit }, v) {
    commit("SET_DASHBOARD_PICKUP_MONTHS_PAGE", v);
  },
  setStrategyFrom({ commit }, v) {
    commit("SET_STRATEGY_FROM", v);
  },
  setStrategyTo({ commit }, v) {
    commit("SET_STRATEGY_TO", v);
  },
  setStrategySpotDate({ commit }, v) {
    commit("SET_STRATEGY_SPOT_DATE", v);
  },
  setAvailabilityFrom({ commit }, v) {
    commit("SET_AVAILABILITY_FROM", v);
  },
  setAvailabilityTo({ commit }, v) {
    commit("SET_AVAILABILITY_TO", v);
  },
  fetchRmsAllKpisDaily({ state, commit, rootGetters }, p) {
    const dataset = "all_kpis_daily";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getKpisDaily(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsAllKpisDailyDimension({ state, commit, rootGetters }, p) {
    const dataset = "all_kpis_daily_dimension";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to,
      spot_date: state.strategy_spot_date,
      dimensions: ["roomtype", "segment"],
      dimension_kpis: ["rn"]
    };
    RmsService.getKpisDaily(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsAllKpisDailyCompset({ state, commit, rootGetters }, p) {
    const dataset = "all_kpis_daily_compset";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getAllKpisDailyCompset(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsAllKpisDayCompset({ state, commit, rootGetters }, p) {
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !p.date
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      date: p.date
    };
    RmsService.getAllKpisDayCompset(params).then(response => {
      commit("SET_ALL_KPIS_COMPSET_BY_DATE", {
        date: p.date,
        data: response.data
      });
    });
  },
  fetchRmsWeatherDaily({ state, commit, rootGetters }, p) {
    const dataset = "weather_daily";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getWeatherDaily(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsAllKpisDailyPickup({ state, commit, rootGetters }, p) {
    const dataset = "all_kpis_daily_pickup";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getAllKpisDailyPickup(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsPvpMlosChangesDaily({ state, commit, rootGetters }, p) {
    const dataset = "pvp_mlos_changes_daily";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getPvpMlosChangesDaily(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsAllKpisPeriodDimension({ state, commit, rootGetters }, p) {
    const dataset = "all_kpis_period_dimension";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.strategy_from,
      to_date: state.strategy_to,
      spot_date: state.strategy_spot_date,
      dimensions: ["roomtype", "segment"],
      dimension_kpis: ["rn"]
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsKpisYearSalesMonths({ state, commit, rootGetters }, p) {
    const dataset = "kpis_year_sales_months";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.year_sales_year
    ) {
      return null;
    }

    // fetch the months
    // for each quarter, fetch the quarter
    //
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      year: state.year_sales_year,
      spots: ["otb", "stly", "ly", "bud"],
      kpis: ["occupancy", "adr", "revpar", "room_revenue"]
    };
    RmsService.getKpisMonthly(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  async fetchRmsKpisYearSalesQuarters({ state, commit, rootGetters }, p) {
    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.year_sales_year
    ) {
      return null;
    }

    const promises = [];
    for (let i = 1; i <= 4; i++) {
      const dataset = "kpis_year_sales_q" + i;
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
      // for each quarter, fetch the quarter data
      const initial_month = String(i * 3 - 2).padStart(2, "0");
      const end_month = String(i * 3).padStart(2, "0");
      const from_date = Vue.moment(
        state.year_sales_year + "-" + initial_month + "-01"
      ).format("YYYY-MM-DD");
      const to_date = Vue.moment(
        state.year_sales_year + "-" + end_month + "-01"
      )
        .endOf("month")
        .format("YYYY-MM-DD");
      let params = {
        hotel_id: rootGetters.current_hotel.id,
        from_date,
        to_date,
        spots: ["otb", "stly", "ly", "bud"],
        kpis: ["occupancy", "adr", "revpar", "room_revenue"]
      };
      const response = RmsService.getKpisPeriod(params);
      promises.push(response);
    }
    const responses = await Promise.all(promises);
    responses.forEach((response, i) => {
      const dataset = "kpis_year_sales_q" + (i + 1);
      commit("GET_DATASET", { dataset: dataset, data: response.data });
    });
    // when promise all are resolved
    commit("SET_DATASET_STATUS", {
      dataset: "kpis_year_sales_quarters",
      status: 2
    });
  },
  fetchRmsKpisYearSalesYear({ state, commit, rootGetters }, p) {
    const dataset = "kpis_year_sales_year";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.year_sales_year
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.year_sales_year + "-01-01",
      to_date: state.year_sales_year + "-12-31",
      spots: ["otb", "stly", "ly", "bud"],
      kpis: ["occupancy", "adr", "revpar", "room_revenue"]
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsMonthKpis({ commit, rootGetters }, p) {
    const dataset = "month_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_year ||
      !state.current_pickup_month
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "revpar", "intermediation"],
      from_date: Vue.moment(
        state.current_pickup_year + "-" + state.current_pickup_month + "-01"
      )
        .startOf("month")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment(
        state.current_pickup_year + "-" + state.current_pickup_month + "-01"
      )
        .endOf("month")
        .format("YYYY-MM-DD")
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYtdMonthKpis({ commit, rootGetters }, p) {
    const dataset = "ytd_month_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "intermediation"],
      from_date: Vue.moment(
        state.current_pickup_year + "-" + state.current_pickup_month + "-01"
      )
        .startOf("month")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment(
        state.current_pickup_year + "-" + state.current_pickup_month + "-01"
      ).format("YYYY-MM-DD")
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsKpisMonthGraph({ commit, rootGetters }, p) {
    const dataset = "kpis_month_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_pickup_month ||
      !state.current_pickup_year
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["occupancy", "adr"],
      month: state.current_pickup_month,
      year: state.current_pickup_year
    };
    RmsService.getKpisMonthGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setRmsHotelsKpisPeriod({ commit }, period) {
    commit("SET_CURRENT_HOTELS_KPIS_PERIOD", period);
  },
  fetchRmsHotelsKpisPeriod({ commit, rootGetters }, p) {
    const dataset = "hotels_kpis_period";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_hotels_kpis_period ||
      state.current_hotels_kpis_period.length < 2
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "occupancy", "adr", "rn"],
      from_date: state.current_hotels_kpis_period[0],
      to_date: state.current_hotels_kpis_period[1]
    };
    RmsService.getHotelsKpisPeriodTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsHotelsKpisPeriodGraph({ commit, rootGetters }, p) {
    const dataset = "hotels_kpis_period_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.current_hotels_kpis_period ||
      state.current_hotels_kpis_period < 2
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "occupancy", "adr", "rn"],
      from_date: state.current_hotels_kpis_period[0],
      to_date: state.current_hotels_kpis_period[1]
    };
    RmsService.getHotelsKpisPeriodGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setRmsHotelsKpiHover({ commit }, v) {
    commit("SET_RMS_HOTELS_KPI_HOVER", v);
  },
  setRmsStrategyDateHover({ commit }, v) {
    commit("SET_RMS_STRATEGY_DATE_HOVER", v);
  },
  setRmsStrategyKpiHover({ commit }, v) {
    commit("SET_RMS_STRATEGY_KPI_HOVER", v);
  },
  setRmsAvailabilityRoomtypeKpiHover({ commit }, v) {
    commit("SET_AVAILABILITY_ROOMTYPE_KPI_HOVER", v);
  },
  setShow({ commit, rootGetters, dispatch }, v) {
    commit("SET_SHOW", v);
    // No volem que l'objecte user_settings sigui reactiu perquè no s'envia bé al servidor sino
    const user_settings = Object.assign({}, rootGetters.getUserSettings);
    const spots_storage =
      user_settings && user_settings["veetal_strategy_spots"]
        ? user_settings["veetal_strategy_spots"]
        : {};
    if (!spots_storage[rootGetters.current_hotel.id]) {
      spots_storage[rootGetters.current_hotel.id] = {};
    }
    spots_storage[rootGetters.current_hotel.id][v.vs] = v.value;
    user_settings["veetal_strategy_spots"] = spots_storage;
    dispatch("setUserSettings", user_settings);
  },
  setRmsStrategyUserSettings({ commit, rootGetters, dispatch }, v) {
    const user_settings = Object.assign({}, rootGetters.getUserSettings);
    const storage =
      user_settings && user_settings["rms_strategy"]
        ? user_settings["rms_strategy"]
        : {};
    if (!storage[rootGetters.current_hotel.id]) {
      storage[rootGetters.current_hotel.id] = {};
    }
    storage[rootGetters.current_hotel.id] = v;
    user_settings["rms_strategy"] = storage;
    dispatch("setUserSettings", user_settings);
  },
  setRmsYearSalesCommission({ commit }, v) {
    commit("SET_YEAR_SALES_COMMISSION", v);
  },
  setRmsYearSalesLyYear({ commit }, v) {
    commit("SET_YEAR_SALES_LY_YEAR", v);
  },
  setRmsYearSalesKpiGraph({ commit }, v) {
    commit("SET_YEAR_SALES_KPI_GRAPH", v);
  },
  setRmsYearSalesKpiHover({ commit }, v) {
    commit("SET_YEAR_SALES_KPI_HOVER", v);
  },
  setRmsHotelsSearchQuery({ commit }, v) {
    commit("SET_RMS_HOTELS_SEARCH_QUERY", v);
  },
  fetchRmsYearSales({ commit, rootGetters }, p) {
    const dataset = "year_sales";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      state.year_sales_commission == ""
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "adr",
        "rn",
        "revpar",
        "intermediation"
      ]
    };
    RmsService.getYearSalesTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsStrategyRangeKpis({ commit, rootGetters }, p) {
    const dataset = "strategy_range_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "occupancy", "adr", "rn", "revpar"],
      from_date: state.strategy_from,
      to_date: state.strategy_to
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsStrategyRangeKpisTable({ commit, rootGetters }, p) {
    const dataset = "strategy_range_kpis_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: [
        "occupancy",
        "free",
        "locked",
        "groups_locked",
        "groups_confirmed",
        "house_use",
        "cupos",
        "rn",
        "adr",
        "room_revenue",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "bud", "stly", "ly", "stlyn", "lyn"],
      vs: "otb",
      expand: "channel",
      from_date: state.strategy_from
        ? state.strategy_from
        : Vue.moment().format("YYYY-MM-DD"),
      to_date: state.strategy_to
        ? state.strategy_to
        : Vue.moment().format("YYYY-MM-DD")
    };
    return RmsService.getKpisRangeTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsStrategyRangeKpisPickupsTable({ commit, rootGetters }, p) {
    const dataset = "strategy_range_kpis_pickups_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.strategy_from ||
      !state.strategy_to
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: [
        "occupancy",
        "free",
        "locked",
        "groups_locked",
        "groups_confirmed",
        "house_use",
        "cupos",
        "rn",
        "adr",
        "room_revenue",
        "revpar"
      ],
      spots: ["pickup"],
      vs: "pickup_stly",
      expand: "segment",
      from_date: state.strategy_from
        ? state.strategy_from
        : Vue.moment().format("YYYY-MM-DD"),
      to_date: state.strategy_to
        ? state.strategy_to
        : Vue.moment().format("YYYY-MM-DD")
    };
    return RmsService.getKpisRangeTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setDashboardFirstDay({ commit }, v) {
    commit("SET_DASHBOARD_FIRST_DAY", v);
  },
  setDashboardLastDay({ commit }, v) {
    commit("SET_DASHBOARD_LAST_DAY", v);
  },
  fetchRmsDashboardYearSalesMonthlyGraph({ commit, rootGetters }, p) {
    const dataset = "dashboard_year_sales_monthly_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.year_sales_commission ||
      !state.dashboard_first_day ||
      !state.dashboard_last_day ||
      Vue.moment(state.dashboard_first_day).isAfter(
        Vue.moment(state.dashboard_last_day)
      )
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "stly"],
      // spots: ["otb", "stly", "ly", "bud"],
      from_date: state.dashboard_first_day,
      to_date: state.dashboard_last_day,
      breakdown: "monhtly"
    };

    RmsService.getMonthlyGraph(params).then(response => {
      commit("GET_DATASET", {
        dataset: "year_sales_monthly_graph",
        data: response.data
      });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYearSalesMonthlyGraph({ commit, rootGetters }, p) {
    const dataset = "year_sales_monthly_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.year_sales_commission
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "stly", "bud", "ly"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD")
    };
    RmsService.getMonthlyGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYearSalesPeriodGraph({ commit, rootGetters }, p) {
    const dataset = "year_sales_period_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      state.year_sales_commission == ""
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "ly", "bud", "stly"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD")
    };
    RmsService.getYearSalesPeriodGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYearSalesMonthlyGrownGraph({ commit, rootGetters }, p) {
    const dataset = "year_sales_monthly_grown_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      state.year_sales_commission == ""
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spot: "otb",
      spot_vs: "ly",
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD")
    };
    RmsService.getYearSalesMonthlyGrownGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYearSalesTable({ commit, rootGetters }, p) {
    const dataset = "year_sales_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      state.year_sales_commission == ""
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "ly", "bud", "bud_ly", "stly", "stlyn"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD")
    };
    RmsService.getYearSalesPeriodTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsYearSalesPeriodTable({ commit, rootGetters }, p) {
    const dataset = "year_sales_period_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      state.year_sales_commission == ""
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.year_sales_commission,
      year: state.year_sales_ly_year,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation"
      ],
      spots: ["otb", "ly", "bud", "bud_ly", "stly", "stlyn"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD")
    };
    RmsService.getYearSalesPeriodTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsRoomtypeAvailability({ commit, rootGetters }, p) {
    const dataset = "roomtype_availability";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.availability_from
        ? state.availability_from
        : Vue.moment().format("YYYY-MM-DD"),
      to_date: state.availability_to
        ? state.availability_to
        : Vue.moment()
            .add(7, "days")
            .format("YYYY-MM-DD")
    };
    RmsService.getRoomTypesDaily(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setRmsDayGeneralKpiHover({ commit }, v) {
    commit("SET_DAY_GENERAL_KPI_HOVER", v);
  },
  setRmsDayGeneralPickupHover({ commit }, v) {
    commit("SET_DAY_GENERAL_PICKUP_HOVER", v);
  },
  setRmsStrategyKpiTableHover({ commit }, v) {
    commit("SET_RMS_STRATEGY_KPI_TABLE_HOVER", v);
  },
  setRmsStrategyKpiPickupsTableHover({ commit }, v) {
    commit("SET_RMS_STRATEGY_KPI_PICKUPS_TABLE_HOVER", v);
  },
  setRmsDayGeneralRoomtypeHover({ commit }, v) {
    commit("SET_DAY_GENERAL_ROOMTYPE_HOVER", v);
  },
  fetchRmsKpisDay({ rootGetters }, date) {
    let data = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "intermediation",
        "house_use",
        "locked",
        "groups_confirmed"
      ],
      spots: ["otb", "bud", "stly", "stlyn", "ly", "lyn"],
      vs: "otb",
      from_date: date,
      to_date: date
    };
    return RmsService.getKpisDay(params).then(response => response.data);
  },
  fetchRmsKpisDayPickups({ rootGetters }, date) {
    let data = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "occupancy", "rn", "adr", "revpar"],
      spots: ["pickup"],
      vs: "pickup_stly",
      from_date: date,
      to_date: date,
      expand: "roomtype"
    };
    return RmsService.getKpisDay(params).then(response => response.data);
  },
  fetchRmsRoomTypesDay({ rootGetters }, date) {
    let data = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      date: date
    };
    return RmsService.getRoomTypesDay(params).then(response => response.data);
  },
  fetchRmsRoomTypesEvolutionDay({ rootGetters }, date) {
    let data = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      date: date
    };
    return RmsService.getRoomTypesEvolutionDay(params).then(
      response => response.data
    );
  },
  setRoomTypesDayRates({ rootGetters }, value) {
    let data = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      date: value.date,
      roomtypes: value.roomtypes
    };
    params = JSON.parse(JSON.stringify(params));
    return RmsService.setRoomTypesDayRates(params).then(
      response => response.data
    );
  },
  setRoomTypeDayRates({ rootGetters }, data) {
    let ret = null;
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      date: data.date,
      roomtype: data.roomtype
    };
    if (data.hasOwnProperty("pvp")) {
      params.pvp = data.pvp;
    }
    if (data.hasOwnProperty("mlos")) {
      params.mlos = data.mlos;
    }
    params = JSON.parse(JSON.stringify(params));
    return RmsService.setRoomTypeDayRates(params).then(
      response => response.data.status
    );
  },
  setRmsSegmentationReportCommission({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_COMMISSION", v);
  },
  setRmsSegmentationReportBreakdown({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_BREAKDOWN", v);
  },
  setRmsSegmentationReportFrom({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_FROM", v);
  },
  setRmsSegmentationReportTo({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_TO", v);
  },
  setRmsSegmentationReportDimension({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_DIMENSION", v);
  },
  setRmsSegmentationReportKpiGraph({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_KPI_GRAPH", v);
  },
  setRmsSegmentationReportHover({ commit }, v) {
    commit("SET_SEGMENTATION_REPORT_HOVER", v);
  },
  fetchRmsSegmentationReportGraph({ commit, rootGetters }, p) {
    const dataset = "segmentation_report_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.segmentation_report_commission ||
      !state.segmentation_report_breakdown ||
      !state.segmentation_report_from ||
      !state.segmentation_report_to ||
      !state.segmentation_report_dimension ||
      !state.segmentation_report_kpi_graph
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      commission: state.segmentation_report_commission,
      breakdown: state.segmentation_report_breakdown,
      from_date: state.segmentation_report_from,
      to_date: state.segmentation_report_to,
      dimension: state.segmentation_report_dimension,
      kpi: state.segmentation_report_kpi_graph,
      spot_vs: "stly"
    };
    RmsService.getSegmentationReportGraph(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsSegmentationReportTable({ commit, rootGetters }, p) {
    const dataset = "segmentation_report_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });

    // Required params
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.segmentation_report_commission ||
      !state.segmentation_report_breakdown ||
      !state.segmentation_report_from ||
      !state.segmentation_report_to ||
      !state.segmentation_report_dimension ||
      !state.segmentation_report_kpi_graph
    ) {
      return null;
    }

    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.segmentation_report_from,
      to_date: state.segmentation_report_to,
      commission: state.segmentation_report_commission,
      breakdown: state.segmentation_report_breakdown,
      dimension: state.segmentation_report_dimension,
      kpis: ["room_revenue", "rn", "adr", "revpar", "occupancy"],
      spot_vs: "stly"
    };
    RmsService.getSegmentationReportTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setRmsStrategyVsSpot({ commit }, spot) {
    commit("SET_STRATEGY_VS_SPOT", spot);
  },
  setRmsDashboardYearFullView({ commit }, v) {
    commit("SET_DASHBOARD_YEAR_FULL_VIEW", v);
  },
  setRmsDashboardYear({ commit }, v) {
    commit("SET_DASHBOARD_YEAR", v);
  },
  fetchRmsDashboardKpisPickupMonthGraph({ commit, rootGetters }, p) {
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let init_month = state.dashboard_pickup_months_page * 3;
    let end_month = (state.dashboard_pickup_months_page + 1) * 3;

    let cnt = 0;
    for (let i = init_month; i < end_month; i++) {
      let month = Vue.moment().add(i, "month");
      let dataset = "kpis_pickup_month_" + cnt++ + "_graphs";
      let dataset_status = "dashboard_kpis_pickup_month_graph";
      commit("SET_DATASET_STATUS", { dataset: dataset_status, status: 1 });
      let params = {
        hotel_id: rootGetters.current_hotel.id,
        kpis: ["occupancy", "adr", "room_revenue", "revpar"],
        month: month.format("M"),
        year: month.format("YYYY")
      };
      RmsService.getKpisPickupMonthGraph(params).then(response => {
        commit("GET_DATASET", { dataset: dataset, data: response.data });
        commit("SET_DATASET_STATUS", { dataset: dataset_status, status: 2 });
      });
    }
  },
  fetchRmsDashboardBookingPaceMonthGraphs({ commit, rootGetters }, p) {
    // Required params
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let init_month = state.dashboard_pickup_months_page * 3;
    let end_month = (state.dashboard_pickup_months_page + 1) * 3;

    let cnt = 0;
    for (let i = init_month; i < end_month; i++) {
      // for (let i = 0; i < 4; i++) {
      let month = Vue.moment().add(i, "month");
      let params = {
        hotel_id: rootGetters.current_hotel.id,
        kpis: ["occupancy", "adr", "room_revenue", "revpar"],
        month: month.format("M"),
        year: month.format("YYYY")
      };

      // let dataset2 = "booking_pace_" + i;
      let dataset2 = "booking_pace_" + cnt++;
      let dataset2_status = "dashboard_booking_pace_month_graphs";
      commit("SET_DATASET_STATUS", { dataset: dataset2_status, status: 1 });
      RmsService.getBookingPace(params).then(response => {
        commit("GET_DATASET", { dataset: dataset2, data: response.data });
        commit("SET_DATASET_STATUS", { dataset: dataset2_status, status: 2 });
      });
    }
  },
  setRevenueFrom({ commit }, v) {
    commit("SET_REVENUE_FROM", v);
  },
  setRevenueTo({ commit }, v) {
    commit("SET_REVENUE_TO", v);
  },
  setRevenueVs({ commit }, v) {
    commit("SET_REVENUE_VS", v);
  },
  fetchRmsRevenueSummaryTable({ commit, rootGetters }, p) {
    const dataset = "revenue_summary_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: [
        "room_revenue",
        "occupancy",
        "rn",
        "adr",
        "revpar",
        "revenue",
        "fb_revenue"
      ],
      spots: ["otb", "stly", "stlyn", "ly", "lyn", "bud", "pickup"],
      from_date: state.revenue_from,
      to_date: state.revenue_to
    };
    RmsService.getRevenueSummaryTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsRevenueChannelsTable({ commit, rootGetters }, p) {
    const dataset = "revenue_channels_table";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["room_revenue", "rn", "adr"],
      dimension: "segment",
      spot_vs: state.revenue_vs,
      from_date: state.revenue_from,
      to_date: state.revenue_to
    };
    RmsService.getRevenueChannelsTable(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsRevenueServicetypeData({ commit, rootGetters }, p) {
    const dataset = "revenue_servicetype_data";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (!rootGetters.current_hotel || !rootGetters.current_hotel.id) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: ["revenue"],
      dimension: "servicetype",
      spot_vs: state.revenue_vs,
      from_date: state.revenue_from,
      to_date: state.revenue_to
    };
    RmsService.getRevenueServicetypeData(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsRevenueRangeKpis({ commit, rootGetters }, p) {
    const dataset = "revenue_range_kpis";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.revenue_from ||
      !state.revenue_to
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      kpis: [
        "occupancy",
        "adr",
        "rn",
        "revpar",
        "intermediation",
        "available",
        "canceled",
        "no_show"
      ],
      from_date: state.revenue_from,
      to_date: state.revenue_to
    };
    RmsService.getKpisPeriod(params).then(response => {
      commit("GET_DATASET", { dataset: dataset, data: response.data });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },

  fetchRmsRevenueDaysToReservationGraph({ commit, rootGetters }, p) {
    const dataset = "revenue_days_to_reservation_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.revenue_from ||
      !state.revenue_to
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.revenue_from,
      to_date: state.revenue_to,
      spot_vs: state.revenue_vs
    };
    RmsService.getRevenueDaysToReservationGraph(params).then(response => {
      commit("GET_DATASET", {
        dataset: dataset,
        data: response.data
      });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  fetchRmsRevenueDaysToCancellationGraph({ commit, rootGetters }, p) {
    const dataset = "revenue_days_to_cancellation_graph";
    commit("SET_DATASET_STATUS", { dataset: dataset, status: 1 });
    if (
      !rootGetters.current_hotel ||
      !rootGetters.current_hotel.id ||
      !state.revenue_from ||
      !state.revenue_to
    ) {
      return null;
    }
    let params = {
      hotel_id: rootGetters.current_hotel.id,
      from_date: state.revenue_from,
      to_date: state.revenue_to,
      spot_vs: state.revenue_vs
    };
    RmsService.getRevenueDaysToCancellationGraph(params).then(response => {
      commit("GET_DATASET", {
        dataset: dataset,
        data: response.data
      });
      commit("SET_DATASET_STATUS", { dataset: dataset, status: 2 });
    });
  },
  setDashboardGroupVsData({ commit }, v) {
    commit("SET_DASHBOARD_GROUP_VS_DATA", v);
  },
  getHotelKpisPeriod({ commit }, hotel) {
    let periods = {
      ytd: {
        from_date: Vue.moment()
          .startOf("year")
          .format("YYYY-MM-DD"),
        to_date: Vue.moment().format("YYYY-MM-DD")
      },
      full_year: {
        from_date: Vue.moment()
          .startOf("year")
          .format("YYYY-MM-DD"),
        to_date: Vue.moment()
          .endOf("year")
          .format("YYYY-MM-DD")
      },
      mtd: {
        from_date: Vue.moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        to_date: Vue.moment().format("YYYY-MM-DD")
      },
      full_month: {
        from_date: Vue.moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        to_date: Vue.moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      },
      next_month: {
        from_date: Vue.moment()
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to_date: Vue.moment()
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD")
      }
    };
    let actions = [];

    //Get data for progress bars
    for (let period in periods) {
      let params = {
        hotel_id: hotel.id,
        kpis: [
          "room_revenue",
          "adr",
          "occupancy",
          "rn",
          "revpar",
          "intermediation"
        ],
        from_date: periods[period].from_date,
        to_date: periods[period].to_date,
        spots: ["otb", state.dashboard_group_vs_data], // Bud debe ser dinamico
        group: hotel.children_number > 0 ? 1 : 0,
        no_children: 1
      };
      // ACtion 0-4
      actions.push(RmsService.getHotelsKpisPeriodTable(params));
    }

    // Get year graph data
    let params = {
      hotel_id: hotel.id,
      commission: state.year_sales_commission,
      kpis: ["room_revenue"],
      spots: ["otb", "stly"],
      from_date: Vue.moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      to_date: Vue.moment()
        .endOf("year")
        .format("YYYY-MM-DD"),
      breakdown: "monhtly"
    };
    // Action 5
    actions.push(RmsService.getMonthlyGraph(params));

    // Get month pickup graph data
    let params_pickup = {
      hotel_id: hotel.id,
      kpis: ["occupancy", "adr", "room_revenue", "revpar"],
      month: Vue.moment().format("M"),
      year: Vue.moment().format("YYYY")
    };
    // Action 6
    actions.push(RmsService.getKpisPickupMonthGraph(params_pickup));

    // Get next month pickup graph data
    let params_pickup_next_month = {
      hotel_id: hotel.id,
      kpis: ["occupancy", "adr", "room_revenue", "revpar"],
      month: Vue.moment()
        .add(1, "month")
        .format("M"),
      year: Vue.moment()
        .add(1, "month")
        .format("YYYY")
    };
    // Action 7
    actions.push(RmsService.getKpisPickupMonthGraph(params_pickup_next_month));

    // ait for all responses
    commit("SET_DASHBOARD_GROUP_STATUS", 1);
    Promise.all(actions).then(values => {
      let item = {
        ytd: {
          value: values[0].data.room_revenue[0].otb,
          vs_value:
            values[0].data.room_revenue[0][state.dashboard_group_vs_data]
        },
        full_year: {
          value: values[1].data.room_revenue[0].otb,
          vs_value:
            values[1].data.room_revenue[0][state.dashboard_group_vs_data]
        },
        mtd: {
          value: values[2].data.room_revenue[0].otb,
          vs_value:
            values[2].data.room_revenue[0][state.dashboard_group_vs_data]
        },
        full_month: {
          value: values[3].data.room_revenue[0].otb,
          vs_value:
            values[3].data.room_revenue[0][state.dashboard_group_vs_data]
        },
        year_sales_monthly_graph: values[5].data,
        kpis_pickup_month_graphs: values[6].data,
        kpis_pickup_next_month_graphs: values[7].data,

        year: values[0].data, // Ahora es budget y debe ser dinamico
        month: values[2].data, // Ahora es budget y debe ser dinamico
        next_month: values[4].data // Ahora es budget y debe ser dinamico
      };
      commit("SET_HOTEl_DATA", {
        hotel: hotel,
        item: item
      });
      commit("SET_DASHBOARD_GROUP_STATUS", 2);
    });
  },
  setHotelListItemSlide({ commit }, v) {
    commit("SET_HOTEL_LIST_ITEMS_SLIDE", v);
  },
  getShowSpots({ rootGetters, dispatch }) {
    // No volem que l'objecte user_settings sigui reactiu perquè no s'envia bé al servidor sino
    const user_settings = Object.assign({}, rootGetters.getUserSettings);
    const spots_storage =
      user_settings && user_settings["veetal_strategy_spots"]
        ? user_settings["veetal_strategy_spots"]
        : {};
    if (
      !rootGetters.current_hotel ||
      !spots_storage[rootGetters.current_hotel.id]
    ) {
      spots_storage[rootGetters.current_hotel.id] = {
        bud: true,
        ly: false,
        pickup_1: true,
        pickup_2: false,
        pickup_3: true,
        pickup_4: false,
        pickup_5: true,
        pickup_6: true,
        stly: true
      };
      user_settings["veetal_strategy_spots"] = spots_storage;
      dispatch("setUserSettings", user_settings);
    }
    return spots_storage[rootGetters.current_hotel.id];
  },
  initStrategyShowSpots({ dispatch, commit }) {
    dispatch("getShowSpots").then(spots => {
      for (let key in spots) {
        commit("SET_SHOW", {
          vs: key,
          value: spots[key]
        });
      }
    });
  }
};

export const getters = {
  rms_available_months: state => {
    let months = [];
    let initial_date = Vue.moment().startOf("month");
    for (let i = 0; i < 12; i++) {
      let item = {
        year: initial_date
          .clone()
          .add(i, "month")
          .format("YYYY"),
        year_label: initial_date
          .clone()
          .add(i, "month")
          .format("YY"),
        month: initial_date
          .clone()
          .add(i, "month")
          .format("MM"),
        month_label: initial_date
          .clone()
          .add(i, "month")
          .format("MMMM")
      };
      months.push(item);
    }
    return months;
  },
  commission_types() {
    return [
      { id: "mixed", name: i18n.t("mixed") },
      { id: "gross", name: i18n.t("gross") },
      { id: "net", name: i18n.t("net") }
    ];
  },
  getRmsDayKpis: state => date => {
    return state.all_kpis_daily && state.all_kpis_daily[date]
      ? state.all_kpis_daily[date]
      : null;
  },
  rms_revenue_summary_data_table: state => {
    if (state.revenue_summary_table) {
      const keys = ["room_revenue", "occupancy", "rn", "adr", "revpar"];
      let rows = keys.map(key => ({
        kpi: key,
        otb: state.revenue_summary_table.otb[key],
        stly: state.revenue_summary_table.stly[key],
        ly: state.revenue_summary_table.ly[key],
        bud: state.revenue_summary_table.bud[key]
      }));
      return rows;
    }
    return [];
  },
  rms_revenue_channels_graph_data: state => {
    let table = state.revenue_channels_table;
    let revenue_vs = state.revenue_vs;
    if (table) {
      let data = {
        labels: {},
        series: {
          room_revenue: {
            otb: []
          },
          rn_adr: {
            otb: []
          }
        }
      };
      data.labels = table.map(item => i18n.t(item.dimension));
      data.series["room_revenue"]["otb"] = table.map(item => item.room_revenue);
      data.series["room_revenue"][revenue_vs] = table.map(
        item => item.room_revenue_vs
      );
      data.series["rn_adr"]["otb"] = table.map(item => [
        String(item.dimension),
        item.adr === null ? 0 : item.adr,
        item.rn === null ? 0 : item.rn
      ]);
      data.series["rn_adr"][revenue_vs] = table.map(item => [
        String(item.dimension),
        item.adr_vs === null ? 0 : item.adr_vs,
        item.rn_vs === null ? 0 : item.rn_vs
      ]);
      return data;
    }
    return {};
  },
  rms_revenue_servicetype_data: state => {
    let info = state.revenue_servicetype_data;
    if (info) {
      let data = {
        labels: {},
        series: {}
      };
      let revenue_vs = state.revenue_vs;
      /* data.labels = info.map(item => item.dimension);
                  data["series"]["otb"] = info.map(item => item.revenue);
                  data["series"][revenue_vs] = info.map(item => item.revenue_vs); */
      let info_ordered = info.sort((a, b) => b.revenue - a.revenue);
      data.labels = info_ordered.map(item => item.dimension);
      data.series.otb = info_ordered.map(item => item.revenue);
      data.series[revenue_vs] = info_ordered.map(item => item.revenue_vs);
      return data;
    }
    return {};
  },
  rms_revenue_days_to_reservation_graph: state => {
    let info = state.revenue_days_to_reservation_graph;
    if (info) {
      let data = {
        labels: {},
        series: {}
      };
      let revenue_vs = state.revenue_vs;
      data.labels = info.labels;
      data.series.otb = info.otb;
      data.series[revenue_vs] = info[revenue_vs];
      return data;
    }
    return {};
  },
  rms_revenue_days_to_cancellation_graph: state => {
    let info = state.revenue_days_to_cancellation_graph;
    if (info) {
      let data = {
        labels: {},
        series: {}
      };
      let revenue_vs = state.revenue_vs;
      data.labels = info.labels;
      data.series.otb = info.otb;
      data.series[revenue_vs] = info[revenue_vs];
      return data;
    }
    return {};
  },
  rms_get_dimension_period_graph: state => {
    let max = 10;
    let graph = state.segmentation_report_graph;
    let dimension = state.segmentation_report_dimension;

    if (dimension == "channel") {
      const graph_ordered = Object.keys(graph.otb).sort(
        (a, b) => graph.otb[b][0] - graph.otb[a][0]
      );
      if (graph_ordered.length > max) {
        let first = graph_ordered.slice(0, max);
        let rest = graph_ordered.slice(max);

        if (Object.keys(graph.otb).length > 0) {
          let sum = rest.reduce((total, key) => {
            graph.otb[key].forEach((num, idx) => {
              total[idx] = (total[idx] || 0) + num;
            });
            return total;
          }, []);

          graph.otb = first.reduce(
            (obj, key) => ({ ...obj, [key]: graph.otb[key] }),
            {}
          );
          graph.otb[i18n.t("rest")] = sum;
        }

        if (Object.keys(graph.stly).length > 0) {
          let sum2 = rest.reduce((total, key) => {
            if (graph.stly[key]) {
              graph.stly[key].forEach((num, idx) => {
                total[idx] = (total[idx] || 0) + num;
              });
            }
            return total;
          }, []);

          graph.stly = first.reduce(
            (obj, key) => ({ ...obj, [key]: graph.stly[key] }),
            {}
          );
          graph.stly[i18n.t("rest")] = sum2;
        }
      }
    }

    return graph;
  },

  rms_filtered_hotels_kpis_period_graph: (state, getters) => {
    if (state.hotels_search_query && state.hotels_kpis_period_graph.kpis) {
      const hotels_search_keys = getters.getHotelsSearchKeys;
      let indexes = state.hotels_kpis_period_graph.labels
        .map((item, index) => {
          const hotel = hotels_search_keys.find(h => h.name === item);
          return hotel &&
            hotel.key.includes(state.hotels_search_query.toLowerCase())
            ? index
            : null;
        })
        .filter(item => item !== null);
      const kpis = {};
      for (let kpi in state.hotels_kpis_period_graph.kpis) {
        kpis[kpi] = {};
        for (let spot in state.hotels_kpis_period_graph.kpis[kpi]) {
          kpis[kpi][spot] = indexes.map(
            index => state.hotels_kpis_period_graph.kpis[kpi][spot][index]
          );
        }
      }
      return {
        labels: indexes.map(
          index => state.hotels_kpis_period_graph.labels[index]
        ),
        kpis: kpis
      };
    }
    return state.hotels_kpis_period_graph;
  }
};
