export const state = {
  language_options: [
    {
      iso: "es",
      flag: "es",
      locale: "es-ES",
      numeral_locale: "es-ES",
      name: "Español",
      literal: "spanish",
      currency: "euro",
      number1_format: "0,0.0",
      percent1_format: "0,0.0",
      number_format: "0,0[.]0",
      percent_format: "0,0[.]0",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "",
      money_post_symbol: "€",
      date_format: "d-m-Y",
      date_format_friendly: "d-m-Y",
      date_format_friendly_full: "d-m-Y",
      decimals: 1
    },
    {
      iso: "en",
      flag: "gb",
      locale: "en-EN",
      numeral_locale: "en-GB",
      name: "Inglés",
      literal: "english",
      currency: "dollar",
      currency_symbol: "$",
      /* number1_format: "0.0,0",
      percent1_format: "0.0,0",
      number_format: "0.0[,]0",
      percent_format: "0.0[,]0", */
      number1_format: "0,0.0",
      percent1_format: "0,0.0",
      number_format: "0,0[.]0",
      percent_format: "0,0[.]0",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "$",
      money_post_symbol: "",
      date_format: "m-d-Y",
      date_format_friendly: "m-d-Y",
      date_format_friendly_full: "m-d-Y",
      decimals: 1
    },
    {
      iso: "fr",
      flag: "fr",
      locale: "fr-FR",
      numeral_locale: "fr-FR",
      name: "Francés",
      literal: "french",
      currency: "euro",
      number1_format: "0,0.0",
      percent1_format: "0,0.0",
      number_format: "0,0[.]0",
      percent_format: "0,0[.]0",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "",
      money_post_symbol: "€",
      date_format: "d-m-Y",
      date_format_friendly: "d-m-Y",
      date_format_friendly_full: "d-m-Y",
      decimals: 1
    },
    {
      iso: "es",
      flag: "es",
      locale: "es-ES",
      numeral_locale: "es-ES",
      name: "Español",
      literal: "spanish",
      currency: "euro",
      number1_format: "0,0.00",
      percent1_format: "0,0.00",
      number_format: "0,0[.]00",
      percent_format: "0,0[.]00",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "",
      money_post_symbol: "€",
      date_format: "d-m-Y",
      date_format_friendly: "d-m-Y",
      date_format_friendly_full: "d-m-Y",
      decimals: 2
    },
    {
      iso: "en",
      flag: "gb",
      locale: "en-EN",
      numeral_locale: "en-GB",
      name: "Inglés",
      literal: "english",
      currency: "dollar",
      currency_symbol: "$",
      /* number1_format: "0.0,00",
      percent1_format: "0.0,00",
      number_format: "0.0[,]00",
      percent_format: "0.0[,]00", */
      number1_format: "0,0.00",
      percent1_format: "0,0.00",
      number_format: "0,0[.]00",
      percent_format: "0,0[.]00",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "$",
      money_post_symbol: "",
      date_format: "m-d-Y",
      date_format_friendly: "m-d-Y",
      date_format_friendly_full: "m-d-Y",
      decimals: 2
    },
    {
      iso: "fr",
      flag: "fr",
      locale: "fr-FR",
      numeral_locale: "fr-FR",
      name: "Francés",
      literal: "french",
      currency: "euro",
      number1_format: "0,0.00",
      percent1_format: "0,0.00",
      number_format: "0,0[.]00",
      percent_format: "0,0[.]00",
      number0_format: "0,0",
      percent0_format: "0,0",
      money_pre_symbol: "",
      money_post_symbol: "€",
      date_format: "d-m-Y",
      date_format_friendly: "d-m-Y",
      date_format_friendly_full: "d-m-Y",
      decimals: 2
    } /*, DE MOMENT DESHABILITEM EL CATALÀ PERQUE DONA PROBLEMES DE TRADUCCIÓ
    {
      iso: "ca",
      flag: "es-ct",
      locale: "ca-ES",
      numeral_locale: "es-ES",
      name: "Catalán",
      literal: "catalan",
      currency: "euro",
      number1_format: "0,0.0",
      percent1_format: "0,0.0",
      number_format: "0,0[.]0",
      percent_format: "0,0[.]0",
      money_pre_symbol: "",
      money_post_symbol: "€",
      date_format: "d-m-Y",
      date_format_friendly: "d-m-Y",
      date_format_friendly_full: "d-m-Y",
      decimals: 1
    }*/
  ]
};

export const mutations = {};

export const actions = {};

export const getters = {
  get_language_by_locale: state => (locale, decimals) => {
    return state.language_options.find(
      language => language.locale === locale && language.decimals === decimals
    );
  }
};
