<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
import Vuex from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
import AppLayout from "@/views/layouts/AppLayout";
import LoginLayout from "@/views/layouts/LoginLayout";

export default {
  computed: {
    ...mapGetters({
      current_hotel: "current_hotel",
      layout: "layout",
      authenticated: "authenticated"
    })
  },
  components: {
    "login-layout": LoginLayout,
    "app-layout": AppLayout
  },
  created() {
    //AutoLogin
    const userString = localStorage.getItem("credentials"); // grab user data from local storage
    if (userString) {
      // check to see if there is indeed a user
      const userData = JSON.parse(userString); // parse user data into JSON
      this.$store.dispatch("autoLogin", userData);
      /* window.Tawk_API.onLoad = function() {
        window.Tawk_API.showWidget();
        window.Tawk_API.setAttributes({
          name: userData.name,
          email: userData.username
        });
      }; */
    }

    // Que miri si estic logat
    if (!this.authenticated) {
      // Se necesita login y no está logeado
      this.$store.dispatch("setLoginLayout");
      this.$router.push({
        name: "home"
      });
    } else {
      this.$store.dispatch("setAppLayout");
    }
  }
};
</script>
