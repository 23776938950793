<template>
  <div class="a-ai-assistant">
    <v-img
      src="/img/ai-assistant/ai-assistant-btn.png"
      height="62"
      contain
      class="chat-toggle-button"
      @click="toggleChat"
    ></v-img>
    <v-card v-if="is_chat_open" class="chatbot-popup" ref="chatbot">
      <v-btn
        class="px-0 close"
        color="transparent"
        elevation="0"
        min-width="36px"
        rounded
        absolute
        @click="is_chat_open = false"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
      <div
        v-if="first_open"
        class="chatbot-container first-open d-flex flex-column justify-center"
      >
        <div class="white--text">
          <div class="d-flex justify-center mb-10">
            <div class="logo big">
              <img src="/img/logo/logo-green.svg" />
            </div>
          </div>
          <div class="text-h5 font-weight-bold text-center mb-4">
            Hola, soy Veetal Assistant
          </div>
          <p class="text-body-2 text-center mb-8">
            Estoy aquí para ayudarte a gestionar tu hotel
          </p>
          <div class="chat-input mb-8">
            <v-textarea
              v-model="user_input"
              placeholder="¿En qué te puedo ayudar hoy?"
              type="text"
              background-color="white"
              class="text-body-2 mt-0"
              dense
              rounded
              hide-details
              @keydown="handleKeydown"
            ></v-textarea>
            <v-btn
              min-width="44px"
              class="elevation-0 px-0"
              color="transparent"
              height="auto"
              plain
              absolute
              @click="sendMessage"
            >
              <v-img
                src="/img/ai-assistant/icon-send.svg"
                max-width="20"
              ></v-img>
            </v-btn>
          </div>
          <div class="init-questions d-flex flex-column">
            <div
              v-for="(question, index) in questions"
              :key="index"
              class="question pa-3 d-flex align-center"
              @click="initQuestion(question.text)"
            >
              <div class="layer-opacity"></div>
              <v-img :src="question.image" height="25" contain></v-img>
              <div class="text-caption">
                {{ question.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="chatbot-container">
        <div
          class="chat-header d-flex justify-space-between align-center pr-12"
        >
          <div class="d-flex align-center">
            <img src="/img/ai-assistant/icon-ai-assistant.svg" />
            <div class="text- font-weight-bold white--text">
              Veetal AI Assistant
            </div>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="px-0"
                color="transparent"
                elevation="0"
                min-width="36px"
                rounded
                @click="initConversation"
              >
                <v-icon color="white">mdi-chat-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Nueva conversación</span>
          </v-tooltip>
        </div>
        <div class="chat-window">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="getClassName(message)"
          >
            <div v-if="message.sender === 'assistant'" class="logo">
              <img src="/img/logo/logo-green.svg" />
            </div>
            <div v-if="message.type === 'table'" class="markdown">
              <p>
                Aquí tienes la tabla:
              </p>
              <div class="table-custom">
                <vue-markdown>
                  {{ message.content }}
                </vue-markdown>
                <div class="d-flex flex-column">
                  <v-btn
                    color="white"
                    elevation="0"
                    height="auto"
                    min-width="30px"
                    class="pa-1"
                    rounded-xl
                    @click="showDialog(message.content)"
                  >
                    <v-icon color="secondary">
                      mdi-arrow-expand
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    elevation="0"
                    height="auto"
                    min-width="30px"
                    class="pa-1"
                    rounded-xl
                    @click="downloadTable(message.content)"
                  >
                    <v-icon color="secondary">
                      mdi-tray-arrow-down
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-else-if="message.type === 'links'" class="markdown">
              <p>
                Creo que esto te puede interesar:
              </p>
              <div class="d-flex flex-column links">
                <div v-for="(link, index) in message.content" :key="index">
                  <v-btn
                    color="secondary"
                    elevation="0"
                    height="auto"
                    class="py-2 pl-4 pr-3"
                    rounded
                    small
                    @click="getLinkBtn(link)"
                  >
                    <span>{{ link.button_text }}</span>
                    <img src="/img/ai-assistant/icon-arrows.svg" />
                  </v-btn>
                </div>
              </div>
            </div>
            <vue-markdown v-else class="markdown">
              {{ message.content }}
            </vue-markdown>
          </div>
          <div v-if="is_loading" class="message assistant loading">
            <div class="markdown">
              <div class="loader"></div>
            </div>
          </div>
        </div>
        <div class="chat-footer">
          <div class="chat-input">
            <v-textarea
              v-model="user_input"
              placeholder="Enviar un mensaje a Veetal AI Assistant..."
              type="text"
              background-color="#e3e3e3"
              class="text-body-2 mt-0"
              :disabled="is_loading"
              dense
              rounded
              hide-details
              @keydown="handleKeydown"
            ></v-textarea>
            <v-btn
              min-width="44px"
              class="elevation-0 px-0"
              color="transparent"
              height="auto"
              plain
              absolute
              @click="sendMessage"
            >
              <v-img
                src="/img/ai-assistant/icon-send.svg"
                max-width="20"
              ></v-img>
            </v-btn>
          </div>
          <div class="text-caption mt-2 text-center">
            Veetal AI Assistant puede cometer errores. Recuerda verificar la
            información importante.
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="show_dialog_table" max-width="1000">
      <v-card>
        <v-card-title class="headline mb-4">
          Aqui tienes la tabla
          <v-spacer></v-spacer>
          <v-btn icon @click="show_dialog_table = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <vue-markdown class="a-ai-assistant-markdown" :key="markdown_key">
            {{ table_dialog_content }}
          </vue-markdown>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import VueMarkdown from "vue-markdown-v2";

export default {
  name: "a-ai-assistant",
  components: {
    VueMarkdown
  },
  data: () => ({
    messages: [],
    user_input: "",
    is_chat_open: false,
    first_open: false,
    is_loading: false,
    show_dialog_table: false,
    table_dialog_content: "",
    thread_id: null,
    markdown_key: 0,
    questions: [
      {
        image: "/img/ai-assistant/icon-calendar.svg",
        text:
          "¿Cómo tenemos la ocupación para MWC del año que viene? ¿Mejor o peor que el este año?"
      },
      {
        image: "/img/ai-assistant/icon-comments.svg",
        text:
          "Hazme un resumen de los comentarios del último mes de mis huéspedes en una tabla con las columnas: Temática, número de comentarios positivos y número de comentarios negativos"
      },
      {
        image: "/img/ai-assistant/icon-compset.svg",
        text:
          "En los próximos 15 días, dime qué días estoy mal posicionado respecto a mi compset en Booking.com"
      }
    ]
  }),
  methods: {
    async toggleChat() {
      if (!this.is_chat_open) {
        // Si tenemos en el localstorage
        /* const thread_id =
          localStorage && localStorage !== "undefined"
            ? localStorage.getItem("veetal_ai_assistant_thread_id")
            : null;
        if (thread_id) {
          this.thread_id = thread_id;
          this.is_chat_open = !this.is_chat_open;
          const response = await axios.get(
            "http://veetal-assistant-api.eu-west-1.elasticbeanstalk.com/messages",
            {
              params: {
                thread_id: this.thread_id
              }
            }
          );
          this.messages = response.data.messages;
        } else {
          this.initConversation();
        } */
        this.initConversation();
      } else {
        this.is_chat_open = !this.is_chat_open;
      }
    },
    async initConversation() {
      try {
        const response = await axios.get(
          "http://veetal-assistant-api.eu-west-1.elasticbeanstalk.com/conversation"
        );
        this.messages = [];
        this.first_open = true;
        this.is_chat_open = true;
        this.thread_id = response.data.thread_id;
        // Guardar en localstorage
        localStorage.setItem("veetal_ai_assistant_thread_id", this.thread_id);
      } catch (error) {
        // console.error("Error al hacer scroll en el chat:", error);
      }
    },
    async initQuestion(text) {
      this.user_input = text;
      this.first_open = false;
      this.sendMessage();
    },
    async sendMessage() {
      if (this.user_input.trim() === "") return;

      this.pushMessage("user", this.user_input, "message");

      const user_message = this.user_input;
      this.user_input = "";
      this.is_loading = true;
      this.first_open = false;

      this.scrollToBottom();

      try {
        // Llamar al backend que se comunica con OpenAI
        const response = await axios.get(
          "http://veetal-assistant-api.eu-west-1.elasticbeanstalk.com/message",
          {
            params: {
              message: user_message,
              thread_id: this.thread_id
            }
          }
        );
        this.pushMessageResponse(response.data);
      } catch (error) {
        // console.error("Error al comunicarse con el asistente:", error);
        this.pushMessage(
          "assistant",
          "Hubo un error al procesar tu solicitud. Intenta de nuevo.",
          "message"
        );
      } finally {
        this.is_loading = false;
        this.scrollToBottom();
      }
    },
    getClassName(message) {
      let class_list = ["message"];
      if (message.sender === "user") {
        class_list.push("user");
      } else if (message.sender === "assistant") {
        class_list.push("assistant");
      }
      if (message.class) {
        class_list.push(message.class);
      }
      return class_list.join(" ");
    },
    pushMessage(sender, content, type) {
      this.messages.push({ sender, content, type });
    },
    pushMessageResponse(data) {
      const parts = this.splitMarkdownByTables(data.reply);
      for (const part of parts) {
        this.pushMessage(
          "assistant",
          part.content,
          this.hasTable(part.content) ? "table" : "message"
        );
      }
      if (data.app_links) {
        this.pushMessage("assistant", data.app_links, "links");
      }
    },
    hasTable(markdown) {
      return /\n(\|.*\n)(\|?[-:|\s]*\|[-:|\s]*\n)((\|.*\n)*)/g.test(markdown);
    },
    splitMarkdownByTables(markdownText) {
      // Expresión regular para detectar tablas
      const tableRegex = /(\n\|.*\n)(\|?[-:|\s]*\|[-:|\s]*\n)((\|.*\n)*)/g;
      let parts = [];
      let lastIndex = 0;
      let match;
      while ((match = tableRegex.exec(markdownText)) !== null) {
        // Parte antes de la tabla
        if (match.index > lastIndex) {
          parts.push({
            content: markdownText.substring(lastIndex, match.index),
            isTable: false
          });
        }
        // Tabla completa encontrada (delimitador)
        parts.push({
          content: match[0],
          isTable: true
        });
        // Actualizar el último índice
        lastIndex = tableRegex.lastIndex;
      }
      // Parte después de la última tabla
      if (lastIndex < markdownText.length) {
        parts.push({
          content: markdownText.substring(lastIndex),
          isTable: false
        });
      }
      return parts;
    },
    showDialog(text) {
      this.table_dialog_content = text;
      this.markdown_key += 1;
      this.show_dialog_table = true;
    },
    downloadTable(text) {
      // Convert markdown table to csv
      const rows = text.split("\n");
      if (rows[0].trim() === "") {
        rows.shift();
      }
      const csv = rows
        .map(row => {
          const columns = row.split("|").map(col => col.trim());
          return columns.slice(1, columns.length - 1).join(",");
        })
        .join("\n");
      // Download csv
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "table.csv";
      a.click();
    },
    getLinkBtn(link) {
      if (link.page.includes("event-management")) {
        this.$router.push({
          name: "event-management",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-strategy")) {
        this.$router.push({
          name: "rms_strategy_hotelsdot",
          params: { hotel_slug: link.hotel_id },
          query: { from_date: link.start, to_date: link.end_date }
        });
      } else if (link.page.includes("rate")) {
        this.$router.push({
          name: "rate",
          params: { hotel_slug: link.hotel_id },
          query: { date: link.start }
        });
      } else if (link.page.includes("parity")) {
        this.$router.push({
          name: "parity",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-year-sales-axel")) {
        this.$router.push({
          name: "rms_year_sales",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-pricing")) {
        this.$router.push({
          name: "rms_pricing",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-segment-report")) {
        this.$router.push({
          name: "rms_segment_report",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-channel-report")) {
        this.$router.push({
          name: "rms_channel_report",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("rms-room-types-report")) {
        this.$router.push({
          name: "rms_room_types_report",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("reputation")) {
        this.$router.push({
          name: "reputation",
          params: { hotel_slug: link.hotel_id }
        });
      } else if (link.page.includes("budget")) {
        this.$router.push({
          name: "budget",
          params: { hotel_slug: link.hotel_id }
        });
      }
    },
    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.onEnter();
      }
    },
    onEnter() {
      this.sendMessage();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.chatbot) {
          const element = this.$refs.chatbot.$el || this.$refs.chatbot;
          const chatWindow = element.querySelector(".chat-window");
          chatWindow.scrollTop = chatWindow.scrollHeight;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.a-ai-assistant {
  .chat-toggle-button {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
    border: none;
    width: 60px;
    height: 60px;
    font-size: 16px;
    z-index: 10;
  }
  .chatbot-popup {
    background-color: #eee;
    position: fixed;
    top: 5px;
    right: 5px;
    height: calc(100vh - 10px);
    width: 400px;
    z-index: 500;
    overflow: hidden;
  }
  .chatbot-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    &.first-open {
      background: linear-gradient(
        100deg,
        rgba(105, 69, 204, 1) 0%,
        rgba(29, 153, 149, 1) 100%
      );
      padding: 16px;
    }
  }
  .chat-header {
    background: linear-gradient(
      100deg,
      rgba(105, 69, 204, 1) 0%,
      rgba(29, 153, 149, 1) 100%
    );
    gap: 10px;
    padding: 10px 16px;
    .d-flex {
      gap: 10px;
      img {
        max-width: 25px;
      }
    }
  }
  .chat-footer {
    padding: 16px;
    .text-caption {
      color: #888;
      line-height: 1;
    }
  }
  .chat-input {
    position: relative;
    .v-textarea {
      border-radius: 20px;
      .v-input__slot {
        padding: 12px 45px 10px 15px;
      }
      textarea {
        resize: none;
        field-sizing: content;
        min-height: auto;
        line-height: 1.3;
      }
      &.v-input--is-focused {
        .v-input__slot {
          background-color: #ffffff !important;
        }
      }
    }
    button {
      right: 5px;
      bottom: 10px;
      &:hover::before {
        background-color: transparent !important;
      }
    }
  }
  .chat-window {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow-y: auto;
    scrollbar-color: #828282 #eeeeee;
  }
  .message {
    margin-bottom: 15px;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.3;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    .markdown {
      padding: 12px 16px;
    }
    &.user {
      justify-content: flex-end;
      .markdown {
        border-radius: 20px 0 20px 20px;
        background: linear-gradient(100deg, #c7d3e7 0%, #dae1ec 100%);
        text-align: right;
        max-width: 80%;
      }
    }
    &.assistant {
      .markdown {
        border-radius: 0 20px 20px 20px;
        background-color: white;
        width: 100%;
      }
    }
    &.loading {
      .markdown {
        width: auto;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .markdown {
    overflow-x: hidden;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    hr,
    table {
      margin-bottom: 10px;
    }
    > *:last-child,
    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }
    ul,
    ol {
      padding-left: 15px;
    }
    .table-custom {
      position: relative;
      overflow-x: hidden;
      max-height: 250px;
      table {
        border-collapse: collapse;
        margin-bottom: 0;
        text-align: left;
        width: 100%;
        th,
        td {
          padding: 6px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
        th {
          color: rgba(0, 0, 0, 0.6);
        }
        tr:last-child td {
          border-bottom: none;
        }
        td {
          white-space: nowrap;
        }
      }
      .d-flex {
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        gap: 5px;
      }
      &::after {
        background: linear-gradient(
          90deg,
          rgba(231, 230, 230, 0) 0%,
          rgba(231, 230, 230, 1) 80%
        );
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
    }
    .links {
      gap: 5px;
      .v-btn {
        font-size: 11px;
        text-transform: none;
        .v-btn__content {
          gap: 20px;
        }
        img {
          max-width: 8px;
        }
      }
    }
  }
  .loading-message {
    text-align: left;
    color: #888;
    font-style: italic;
    margin: 8px 0;
  }
  .close {
    top: 10px;
    right: 10px;
  }
  .init-questions {
    gap: 10px;
    .question {
      cursor: pointer;
      gap: 5px;
      position: relative;
      .layer-opacity {
        border-radius: 15px;
        background-color: #ebf1f9;
        opacity: 0.36;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  .logo {
    background-color: white;
    border-radius: 100%;
    max-width: 30px;
    padding: 8px 5px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.big {
      max-width: 70px;
      padding: 18px 15px 16px;
    }
    img {
      max-width: 100%;
    }
  }
  .loader {
    aspect-ratio: 2;
    animation: loader 1s infinite linear;
    --_g: no-repeat radial-gradient(circle closest-side, #32ccc4 100%, #fff);
    background: no-repeat
        radial-gradient(circle closest-side, #32ccc4 100%, #fff) 0% 50%,
      no-repeat radial-gradient(circle closest-side, #5bdbdb 100%, #fff) 50% 50%,
      no-repeat radial-gradient(circle closest-side, #8bf9df 100%, #fff) 100%
        50%;
    background-size: calc(100% / 3) 50%;
    width: 40px;
  }
  @keyframes loader {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%;
    }
  }
}
.a-ai-assistant-markdown {
  overflow-x: visible;
  table {
    border-collapse: collapse;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
    th,
    td {
      padding: 10px 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    th {
      color: rgba(0, 0, 0, 0.6);
    }
    tr:last-child td {
      border-bottom: none;
    }
    //td {
    //  white-space: nowrap;
    //}
  }
}
</style>
